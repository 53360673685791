import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    inputAnatomy.keys
);

const baseStyle = definePartsStyle({
    field: {
        padding: 4,
    },
});

const md = defineStyle({
    lineHeight: '1rem',
    h: 'auto',
    boxSizing: 'border-box',
});

const sizes = {
    md: definePartsStyle({
        field: md,
        addon: md,
    }),
};

export const theme = defineMultiStyleConfig({
    baseStyle,
    sizes,
});
