import * as React from 'react';

const AssetCreditCardsSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x={5.8}
            y={12.6}
            width={36}
            height={24}
            rx={2}
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M5.8 28.028h36" stroke="#111" strokeLinecap="round" />
    </svg>
);

export default AssetCreditCardsSvg;
