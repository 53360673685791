import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { account } from '../../entities/session';
import { getComponentStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';

const WelcomeBoard = () => {
    const accountInfo = account.use();

    const { main, note } = getComponentStyles('WelcomeBoard');

    const content = useContent();

    return (
        <Box m={0} mb={'24px'}>
            <Heading {...main}>
                {content('dashboard.welcomeMain', '{username}', (match, i) => (
                    <span key={i}>{accountInfo?.firstName}</span>
                ))}
            </Heading>
            <Heading {...note}>{content('dashboard.welcomeNote')}</Heading>
        </Box>
    );
};

export default WelcomeBoard;
