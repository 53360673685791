import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    HStack,
    Link,
    Spacer,
    Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ASSET_CRITERIA } from '../../config/config';
import { getPageStyles } from '../../config/theme';
import BreachAssetTypeDisplay from './BreachAssetTypeDisplay';

const BreachAssetTypeItem = ({
    title,
    asset,
    email,
    assetItem,
    mode,
    onResolved,
}: {
    title: string;
    asset: string;
    email: string;
    assetItem: any;
    mode: string;
    onResolved: Function;
}) => {
    const { items } = getPageStyles('BreachDetailsPage');
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <AccordionItem {...items.root}>
            {/* DESKTOP */}
            <AccordionButton
                p={0}
                _hover={{
                    backgroundColor: 'transparent',
                }}
                display={{
                    base: 'none',
                    md: 'flex',
                }}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                <HStack w={'100%'}>
                    <Box>
                        <Text {...items.name}>{title}</Text>
                    </Box>
                    {mode === ASSET_CRITERIA.EXPOSED && (
                        <Box {...items.action}>Take Action</Box>
                    )}
                    {mode === ASSET_CRITERIA.RESOLVED && (
                        <Box {...items.resolved}>Resolved</Box>
                    )}
                    <Spacer />
                    <Link {...items.link}>
                        <HStack>
                            <Text>Details</Text>
                            {!expanded ? <AddIcon /> : <MinusIcon />}
                        </HStack>
                    </Link>
                </HStack>
            </AccordionButton>

            {/* MOBILE */}
            <AccordionButton
                p={0}
                pr={'16px'}
                _hover={{
                    backgroundColor: 'transparent',
                }}
                display={{
                    base: 'block',
                    md: 'none',
                }}
            >
                {mode === ASSET_CRITERIA.EXPOSED && (
                    <Box {...items.action} maxWidth={'77px'}>
                        Take Action
                    </Box>
                )}

                {mode === ASSET_CRITERIA.RESOLVED && (
                    <Box {...items.resolved} maxWidth={'77px'}>
                        Resolved
                    </Box>
                )}

                <Flex direction={'row'} mt={'10px'}>
                    <Box mr={'16px'} textAlign={'left'}>
                        <Text {...items.name}>{title}</Text>
                    </Box>
                    <Spacer />
                    <AddIcon />
                </Flex>
            </AccordionButton>
            {/* MOBILE */}

            <AccordionPanel
                p={{
                    base: '8px 16px 0 0',
                    md: '8px 16px 8px 0',
                }}
            >
                <BreachAssetTypeDisplay
                    asset={asset}
                    item={assetItem}
                    email={email}
                    mode={mode}
                    onResolved={onResolved}
                />
            </AccordionPanel>
        </AccordionItem>
    );
};

export default BreachAssetTypeItem;
