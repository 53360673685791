import { pageContents } from '../entities/contents';
import reactStringReplace from 'react-string-replace';
import { useCallback } from 'react';

const useContent = () => {
    const page = pageContents.use();

    const content = useCallback(
        (key: string, token?: string, replace?: any) => {
            const indexes = key?.split('.');
            const len = indexes.length;

            const getValue = (obj, k) => {
                return obj[k];
            };

            let data = '';

            if (key) {
                if (page) {
                    for (let x = 0; x < len; x++) {
                        const k = indexes[x];
                        if (!data) {
                            data = getValue(page, k);
                        } else {
                            data = getValue(data, k);
                        }
                    }

                    let parseContent: any;
                    if (token && replace && data) {
                        parseContent = reactStringReplace(data, token, replace);
                        return parseContent;
                    }
                }
            }
            return data || key;
        },
        [page]
    );

    return content;
};

export default useContent;
