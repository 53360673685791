import { Box, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import ShieldGreenSvg from '../../assets/ShieldGreenSvg';
import WarningRedSvg from '../../assets/WarningRedSvg';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import BorderedBox from '../layouts/BorderedBox';

const BreachAlerts = ({ email, exposed, resolved }) => {
    const content = useContent();

    const { alerts } = getPageStyles('BreachPage');
    return (
        <Box id={`breachpage-alerts-${email.replaceAll('@', '-')}`} {...alerts.root}>
            <Box>
                <Text {...alerts.title}>{content('breach.breachalerts')}</Text>
            </Box>
            <BorderedBox styleProps={alerts.borderBox}>
                <Flex direction={'row'} gap={2}>
                    <Spacer />
                    <Box margin={'20px 10px'}>
                        <Flex direction={'column'} gap={2}>
                            <Box textAlign={'center'}>
                                <Text {...alerts.count} color={'#DB1B1B'}>
                                    {exposed}
                                </Text>
                            </Box>
                            <Spacer />
                            <Box>
                                <HStack>
                                    <WarningRedSvg style={{ marginTop: '-2px' }} />
                                    <Text {...alerts.status}>Pending Action</Text>
                                </HStack>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box margin={'20px 10px'}>
                        <Flex direction={'column'} gap={2}>
                            <Box textAlign={'center'}>
                                <Text {...alerts.count} color={'#008662'}>
                                    {resolved}
                                </Text>
                            </Box>
                            <Spacer />
                            <Box>
                                <HStack alignItems={'center'}>
                                    <ShieldGreenSvg style={{ marginTop: '-2px' }} />
                                    <Text {...alerts.status}>Resolved</Text>
                                </HStack>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                </Flex>
            </BorderedBox>
        </Box>
    );
};
export default BreachAlerts;
