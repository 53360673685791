import * as React from 'react';

const NewArticlesSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M24 5H0v2h24V5ZM2 12.303l2.168 3.252A1 1 0 0 0 6 15V8H4v3.697L1.832 8.445A1.001 1.001 0 0 0 0 9v7h2v-3.697ZM10 10h3V8h-3c-1.654 0-3 1.346-3 3v2c0 1.654 1.346 3 3 3h3v-2h-3c-.551 0-1-.448-1-1h3v-2H9c0-.552.449-1 1-1ZM20 8l.985 3.901L21.942 8H24l-2 7.919.021.081h-2.058l-.978-3.983L18 15.919l.021.081h-2.058L14 8h2l.985 3.901L17.942 8H20ZM0 19v-2h24v2H0Z"
            fill="#111"
        />
    </svg>
);

export default NewArticlesSvg;
