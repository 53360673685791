import { AddIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import ChevronDownBlackSvg from '../../assets/ChevronDownBlackSvg';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';

const BreachMobileSelect = ({ selectedEmail, emails, showAddEmail, onEmailChange }) => {
    const content = useContent();
    const { tabs, menu } = getPageStyles('BreachPage');

    return (
        <Box
            id={'breachpage-select-tab'}
            {...tabs.root}
            display={{ base: 'block', md: 'none' }}
        >
            <Box backgroundColor={'grayscale.400'}>
                <Menu>
                    <MenuButton {...menu.root}>
                        <Flex direction={'row'} gap={2} verticalAlign={'middle'}>
                            <Text>{selectedEmail.email}</Text>
                            <ChevronDownBlackSvg />
                        </Flex>
                    </MenuButton>
                    <MenuList>
                        {emails &&
                            emails.map((item, index) => {
                                const { email, lastScanDate, label, exposed, resolved } =
                                    item;
                                return (
                                    <MenuItem
                                        key={`email-select-${index}`}
                                        onClick={() =>
                                            onEmailChange({
                                                email,
                                                lastScanDate,
                                                exposed,
                                                resolved,
                                            })
                                        }
                                    >
                                        <VStack alignItems={'baseline'}>
                                            <Text {...tabs.tabItemLabel}>{label}</Text>
                                            <Text {...tabs.tabItemText}>{email}</Text>
                                        </VStack>
                                    </MenuItem>
                                );
                            })}
                    </MenuList>
                </Menu>
                {showAddEmail && (
                    <Button leftIcon={<AddIcon />} variant={'solid'} {...tabs.btnAdd}>
                        {content('breach.addemail')}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default BreachMobileSelect;
