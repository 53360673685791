import { RISK_LEVEL } from '../config/config';
import assessmentPageMock from '../assets/images/SecurityAssessmentLogo.png';

import discoverImg1 from './mock-discover-1.png';
import discoverImg2 from './mock-discover-2.png';
import discoverImg3 from './mock-discover-3.png';
import imgAdsMock from './ads.png';
import { Step } from 'react-joyride';

export const mock = {
    account: {
        firstName: 'John',
        lastName: 'Doe',
        primaryEmail: {
            email: 'kishorebs85@gmail.com',
            lastScanDate: 1674230400000,
        },
        secondaryEmails: [
            { email: 'motemi6951@fsouda.com', lastScanDate: 1674230400000 },
            { email: 'motemi69521@fsouda.com', lastScanDate: 1674230400000 },
        ],
        familyMembers: ['anne.doe@gmail.com', 'jimmydoe@gmail.com'],
        isPrimary: 'true',
    },
    data: {
        assessment: {
            risk: {
                map: {
                    'juan.delacruz@asurion.com': [
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'yes',
                        'yes',
                        'yes',
                    ],
                    'philippides.cayetano@asurion.com': [
                        'yes',
                        'yes',
                        'yes',
                        'yes',
                        'yes',
                        'no',
                        'no',
                        'yes',
                        'yes',
                        'yes',
                    ],
                    'piercarlomartino.penero@asurion.com': [
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        // 'yes',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                        'no',
                    ],
                },
            },
            summary: {
                score: 40,
                risk: RISK_LEVEL.HIGH,
                recommendation: {
                    title: 'Install a password manager',
                    content:
                        'We recommend that you use a password manager. This will help you create complex, unique passwords and store them securely',
                },
                allowReTake: true,
                monthly: [
                    { month: 'Dec', score: 30, risk: 'HIGH' },
                    { month: 'Jan', score: 55, risk: 'MEDIUM' },
                    { month: 'Feb', score: 100, risk: 'LOW' },
                ],
            },
            questions: {
                pre: [
                    {
                        id: 1,
                        type: 'confirm',
                        content: {
                            logo: assessmentPageMock,
                            header: 'How secure are you online?',
                            byLine: 'Find out your security score by answering a few questions about your current security habits.',
                            body: "<ul>You'll also get:<br /><li>personalized security recommendations</li><li>helpful security tips</li></ul><p>Remember to answer honestly. This assessment takes about 5 minutes to complete.</p>",
                            label: 'Start assessment',
                        },
                    },
                ],
                items: [
                    {
                        id: 1,
                        content:
                            'Do you use the same password for different websites or apps?',
                        type: 'polarity',
                    },
                    {
                        id: 2,
                        content:
                            'Do you use a password manager to generate strong passwords and store them securely?',
                        type: 'polarity',
                    },
                    {
                        id: 3,
                        content:
                            'Have you changed your email password in the last 12 months?',
                        type: 'polarity',
                    },
                    {
                        id: 4,
                        content:
                            'Do you use two-factor authentication on your email and other online accounts?',
                        type: 'polarity',
                    },
                    {
                        id: 5,
                        content:
                            'Do you use privacy settings on your social media accounts to control who can see your posts and personal information?',
                        type: 'polarity',
                    },
                    {
                        id: 6,
                        content:
                            'Do you have backup copies of your important files and documents?',
                        type: 'polarity',
                    },
                    {
                        id: 7,
                        content:
                            'Do you use firewalls and anti-virus software to protect your devices?',
                        type: 'polarity',
                    },
                    {
                        id: 8,
                        content:
                            'Do you keep your operating system and applications updated with the latest security patches?',
                        type: 'polarity',
                    },
                    {
                        id: 9,
                        content:
                            'Do you know what to do in case you are notified of a data breach?',
                        type: 'polarity',
                    },
                    {
                        id: 10,
                        content:
                            'Do you regularly review your financial statements or use identity theft protection services such as credit monitoring?',
                        type: 'polarity',
                    },
                ],
                post: [
                    {
                        id: 1,
                        type: 'results',
                        content: {
                            logo: assessmentPageMock,
                            header: "Let's review your answers",
                            label: 'Confirm',
                        },
                    },
                ],
            },
        },
        ads: {
            title: 'Enjoy 3 FREE months when you sign-up today.',
            content:
                'Eliminate the hassles of having to remember your own passwords with McAfee Password Manager.',
            image: imgAdsMock,
        },
        articles: {
            latest: [
                {
                    title: 'Twitter Probed in EU for Pre-Musk Data Leak of 5.4 Million Users',
                    author: 'CNN',
                    date: '14 January 2023',
                },
                {
                    title: 'Meta Fined €390 Million, Ordered to Change Way It Processes Ads',
                    author: 'CNN',
                    date: '10 January 2023',
                },
                {
                    title: 'A $68 Million Scam That Relies on Telecom Insiders',
                    author: 'CNN',
                    date: '8 January 2023',
                },
            ],
            discover: [
                {
                    image: discoverImg1,
                    title: 'Tech in your life',
                    content:
                        'Can a severed finger unlock a phone? Answers to your security question.',
                },
                {
                    image: discoverImg2,
                    title: 'The switch',
                    content:
                        'That illegal streaming site you love? It might be infecting you with malware.',
                },
                {
                    image: discoverImg3,
                    title: 'The switch',
                    content:
                        'Millions of Singaporeans move from cash to digital payments. But some ask whether it’s safe.',
                },
            ],
        },
        guides: {
            'password-security': {
                pre: [
                    {
                        id: 1,
                        type: 'overview',
                        content: {
                            header: 'Install a password manager',
                            description:
                                'You mentioned you don’t use a password manager. Password managers generate and remember strong passwords for you. Most are free and work on both your phone and desktop.',
                            body: `
                                <p>The average person has around 80 online accounts and passwords. That is a lot to remember. Having a unique password for every account is one of the best things you can do to secure yourself online.</p>
<br />
<p>This can be a burden, but luckily there are tools available that can help you store and remember all of your passwords.</p>
<br />
<p>In this guide, we will give you some tips and tricks on how to set up a password manager.</p>
                                `,
                        },
                    },
                ],
                items: [
                    {
                        id: 2,
                        type: 'overview',
                        content: {
                            header: 'Set up a Password Manager',
                            body: `
                            <p>We talked earlier about how using the same password can put you at risk. Luckily there are tools available that can help you store and remember all your passwords.<p><br />

                            <p>Password managers are a simple tool that securely stores all your passwords. Instead of remembering 80 you only need to remember 1 password.</p>

                            <ul>
                             <li>A password manager, like LastPass® or Dashlane®, can create, store, and automatically fill in strong passwords for your accounts so you don’t have to remember them.</li>
                             <li>Many password managers are free to use and work on both your desktop and phone.</li>
                            </ul>
                                `,
                        },
                    },
                    {
                        id: 3,
                        type: 'overview',
                        content: {
                            header: 'Set up a Password Manager 2',
                            body: `
                            <p>We talked earlier about how using the same password can put you at risk. Luckily there are tools available that can help you store and remember all your passwords.<p><br />

                            <p>Password managers are a simple tool that securely stores all your passwords. Instead of remembering 80 you only need to remember 1 password.</p>

                            <ul>
                             <li>A password manager, like LastPass® or Dashlane®, can create, store, and automatically fill in strong passwords for your accounts so you don’t have to remember them.</li>
                             <li>Many password managers are free to use and work on both your desktop and phone.</li>
                            </ul>
                                `,
                        },
                    },
                ],
                post: [],
            },
            'email-security': {
                pre: [],
                items: [],
                post: [],
            },
            'account-security': {
                pre: [],
                items: [],
                post: [],
            },
            'social-media-privacy': {
                pre: [],
                items: [],
                post: [],
            },
            'data-security': {
                pre: [],
                items: [],
                post: [],
            },
            'breach-prevention': {
                pre: [],
                items: [],
                post: [],
            },
            'device-security': {
                pre: [],
                items: [],
                post: [],
            },
            'breach-protection': {
                pre: [],
                items: [],
                post: [],
            },
            'identity-protection': {
                pre: [],
                items: [],
                post: [],
            },
        },
        onboarding: {
            steps: [
                {
                    target: 'body',
                    placement: 'center',
                    disableBeacon: true,
                    title: 'Dashboard',
                    content:
                        'Your personal portal for quick access to detailed data breach monitoring results, individual security score and personalised recommendations, security tips, articles and more.',
                    buttonLabel: "Let's begin",
                },
                {
                    target: '#overviewcard-breach',
                    placement: 'right',
                    disableBeacon: true,
                    title: 'Data Breach Monitoring',
                    content:
                        'Monitors the dark web for your personal info, including email, government IDs, credit card and bank account info. If your data is found on the dark web, you will receive guidance to resolve and secure your breached accounts.',
                    buttonLabel: 'Step :index/:size-1',
                },
                {
                    target: '#overviewcard-assessment',
                    placement: 'right',
                    disableBeacon: true,
                    title: 'Security Assessment',
                    content:
                        'Take a short assessment to find out how safe you are online, receive recommendations to fix security weak spots and improve your online security.',
                    buttonLabel: 'Step :index/:size-1',
                },
                {
                    target: '.discover-articles-list',
                    placement: 'bottom',
                    disableBeacon: true,
                    title: 'Discover Security',
                    content:
                        'Keep yourself up-to-date on hot security issues and topics with curated articles by our in-house experts.',
                    buttonLabel: 'Step :index/:size-1',
                },
                {
                    target: '#headermenu-profile',
                    disableBeacon: true,
                    title: 'Profile',
                    content:
                        'Manage your profile, add or remove additional emails for data breach monitoring, configure monthly reports, manage family users and more. ',
                    buttonLabel: 'Done',
                },
            ] as Array<Step & { buttonLabel: string }>,
        },
    },
};
export default mock;
