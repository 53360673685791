import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    HStack,
} from '@chakra-ui/react';
import React, { ReactElement, useRef } from 'react';

const ConfirmDialog: React.FC<{
    title: any;
    closable?: boolean;
    children?: ReactElement;
    footer?: ReactElement;
    isCentered?: boolean;
    isOpen: boolean;
    onClose: () => void;
    onNo: React.MouseEventHandler;
    onYes: React.MouseEventHandler;
}> = ({
    title,
    closable = true,
    children = <></>,
    footer,
    isCentered = true,
    isOpen,
    onClose,
    onNo,
    onYes,
}) => {
    const cancelRef: any = useRef();

    return (
        <AlertDialog
            onClose={onClose}
            isOpen={isOpen}
            isCentered={isCentered}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent w={{ base: '330px', md: '350px' }}>
                <AlertDialogHeader>{title}</AlertDialogHeader>
                {closable && <AlertDialogCloseButton />}
                <AlertDialogBody>{children}</AlertDialogBody>
                <AlertDialogFooter>
                    {footer ? (
                        footer
                    ) : (
                        <HStack alignItems={'end'} spacing={2}>
                            <Button
                                ref={cancelRef}
                                onClick={onNo}
                                variant={'outline'}
                                width={'60px'}
                            >
                                No
                            </Button>
                            <Button onClick={onYes} variant={'solid'} width={'70px'}>
                                Yes
                            </Button>
                        </HStack>
                    )}
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ConfirmDialog;
