import { Box } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { getComponentStyles } from '../../config/theme';

const SidebarNavItem: React.FC<{
    item: string;
    children: ReactElement;
    isActive?: boolean;
}> = ({ item, children, isActive = false }) => {
    const { item: itemStyles } = getComponentStyles('SidebarNav');
    const { inactive, active, text } = itemStyles;
    const id = `sidebar-item-${item}`;

    return isActive ? (
        <Box id={id} {...active}>
            <Box {...text}>{children}</Box>
        </Box>
    ) : (
        <Box id={id} {...inactive}>
            <Box {...text}>{children}</Box>
        </Box>
    );
};

export default SidebarNavItem;
