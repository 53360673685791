import * as React from 'react';
import { IconProps, Icon } from '@chakra-ui/react';

const SecurityAdvisorLogoOnlyIcon: React.FC<IconProps> = props => (
    <Icon
        width={'24px'}
        height={'28px'}
        viewBox={'0 0 24 28'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m23.38 12.939.001-1.722.008.017v-6.73c-.002-.848-.266-1.095-1.078-1.144l-.318-.018c-.859-.05-1.72-.1-2.566-.239-2.533-.418-4.906-1.283-6.991-2.866-.394-.3-.735-.32-1.153-.033C8.245 2.298 4.87 3.313 1.218 3.356c-.947.012-1.211.252-1.211 1.2C.004 6.527.005 8.5.005 10.474c0 2.428.001 4.856-.004 7.284-.008.227.038.453.135.657.097.205.241.381.42.515 3.49 2.87 6.98 5.738 10.469 8.602.779.641.925.62 1.704-.042a1297.22 1297.22 0 0 1 10.026-8.488c.457-.374.636-.779.636-1.37-.017-1.565-.014-3.129-.012-4.693Zm-21.963-.017-.002-1.58c0-.523 0-1.046.002-1.569.002-1.427.005-2.856-.012-4.284-.005-.491.11-.683.63-.707a19.181 19.181 0 0 0 9.354-2.926c.324-.2.558-.19.877.017a17.8 17.8 0 0 0 7.429 2.735 18.92 18.92 0 0 0 1.675.168c.438.019.633.138.633.652-.01 1.888-.01 3.776-.012 5.664 0 2.077-.002 4.154-.014 6.231a1.182 1.182 0 0 1-.38.774 1441.68 1441.68 0 0 1-4.87 4.136c-1.5 1.27-3 2.54-4.495 3.815-.329.276-.507.24-.813-.015a1900.775 1900.775 0 0 0-9.52-7.852 1.178 1.178 0 0 1-.38-.457 1.216 1.216 0 0 1-.114-.59c.018-1.402.015-2.807.012-4.212Zm5.881 1.919c-.777-.778-1.557-1.56-1.528-2.656.005-1.431.56-2.415 1.624-3.014 1.175-.67 2.4-.636 3.55.13l.055.037c.796.531.803.536 1.584-.016 1.108-.784 2.682-.803 3.755-.045 1.207.852 1.668 2.07 1.341 3.55-.105.478-.025.878.466 1.066.36.14.769-.18.923-.688a4.166 4.166 0 0 0-.146-2.783c-1.101-2.817-4.041-3.953-6.663-2.567-.336.178-.58.2-.928.013-1.505-.811-3.028-.805-4.546 0-1.938 1.026-2.96 3.556-2.173 5.603.533 1.386 1.544 2.414 2.748 3.21.2.131.693.044.89-.132.307-.273.218-.691-.092-.983-.125-.118-.26-.223-.396-.33-.154-.12-.308-.24-.446-.378l-.018-.017Z"
            fill="#fff"
        />
        <path
            d="M9.465 18.218c0-.678.428-.997.898-.762.321.169.62.378.892.623.34.291.595.336.979 0a73.903 73.903 0 0 1 3.492-2.86 1.107 1.107 0 0 1 .912-.124c.407.146.447.672.095 1.03-.395.384-.81.744-1.243 1.081-1.021.842-2.06 1.663-3.071 2.517-.446.374-.84.396-1.285.015-.446-.382-.904-.69-1.333-1.061a1.99 1.99 0 0 1-.336-.459Z"
            fill="#fff"
        />
    </Icon>
);

export default SecurityAdvisorLogoOnlyIcon;
