import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const HeaderMenu = ({ onLogout }) => {
    const handleSignOut = useCallback(() => {
        onLogout();
    }, [onLogout]);

    return (
        <Box>
            <DesktopMenu onSignOut={handleSignOut} />
            <MobileMenu onSignOut={handleSignOut} />
        </Box>
    );
};

export default HeaderMenu;
