import React, { useState } from 'react';
import { account, authToken, loginEmail, registerEmail } from '../entities/session';
import FullWidth from '../components/layouts/FullWidth';
import { useMutation } from '@tanstack/react-query';
import {
    fetchAccountDetails,
    login,
    verifyOtp,
    verifyRegistrationEmailOtp,
} from '../services/apiService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppConfig, AppRoutes } from '../config/config';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import useErrorToast from '../hooks/useErrorToast';
import RouteLink from '../components/common/RouteLink';
import OtpForm from '../components/common/OtpForm';
import useContent from '../hooks/useContent';

const OtpPage = ({
    title,
    confirmation,
    instruction,
    mode,
    id = 'otp-page',
}: {
    title: string;
    confirmation: string;
    instruction: string;
    mode: string;
    id?: string;
}) => {
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get('redirect');
    const content = useContent();
    const email = mode === 'login' ? loginEmail.use() : registerEmail.use();
    const errToast = useErrorToast();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const toast = useToast();
    const [invalidOtp, setInvalidOtp] = useState(false);

    const { mutate: verifyOtpCode, isLoading: isVerifyingOtp } = useMutation({
        mutationFn: mode === 'login' ? verifyOtp : verifyRegistrationEmailOtp,
        onSuccess: (data: any) => {
            if (data?.token) {
                authToken.set(data.token);
                fetchDetails({ email: email || '' });
            }
            if (mode === 'register') {
                window.dataLayer.push({
                    event: 'SecurityAdvisor_DL_Event Registration - Direct Access Register email Success',
                    System: 'Security Advisor',
                    Partner: AppConfig.carrier,
                    Program: AppConfig.program,
                    ID: '',
                    Module: 'Email OTP',
                    MainSubscriberEmail: email,
                });
            } else {
                window.dataLayer.push({
                    event: 'SecurityAdvisor_DL_Event Login Success',
                    System: 'Security Advisor',
                    Partner: AppConfig.carrier,
                    Program: AppConfig.program,
                    ID: '',
                    Module: 'Login OTP',
                    MainSubscriberEmail: email,
                });
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
            setInvalidOtp(true);
            window.dataLayer.push({
                event: 'SecurityAdvisor_DL_Event Login Failed',
                System: 'Security Advisor',
                Partner: AppConfig.carrier,
                Program: AppConfig.program,
                ID: '',
                // Timestamp: now(),
                Module: 'Login OTP',
                MainSubscriberEmail: email,
            });
        },
    });

    const { mutate: resendCode, isLoading: isResendingCode } = useMutation({
        mutationFn: login,
        onSuccess: () => {
            toast({
                status: 'success',
                title: `Your verification code was sent to ${email}`,
            });
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
        },
    });

    const { mutate: fetchDetails, isLoading: isFetchingAccount } = useMutation({
        mutationFn: fetchAccountDetails,
        onSuccess: accountData => {
            account.set(accountData.user);
            loginEmail.set(null);
            if (mode === 'register') {
                registerEmail.set(null);
            }

            if (accountData?.user?.primaryEmail) {
                if (redirectPath) {
                    navigate(redirectPath);
                } else {
                    navigate(AppRoutes.DASHBOARD);
                }
            }
        },
    });

    return (
        <FullWidth id={id}>
            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
                    <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                        <Heading
                            as="h2"
                            mb={2}
                            fontSize={24}
                            noOfLines={1}
                            display="inline-flex"
                            gap={2}
                        >
                            {title}
                        </Heading>
                        <Stack spacing={2}>
                            <Text fontSize="1em">{confirmation}</Text>
                            <Text fontSize="1em">
                                {instruction}
                                <br />

                                <Button
                                    variant="ghost"
                                    disabled={!email}
                                    onClick={() => {
                                        if (email) {
                                            resendCode(email);
                                        }
                                    }}
                                    w="minW"
                                    px={0}
                                    fontWeight={500}
                                    textDecor="underline"
                                    color="black"
                                    pb={0}
                                    isLoading={isResendingCode}
                                >
                                    Resend code
                                </Button>
                            </Text>
                        </Stack>
                    </CardHeader>
                    <CardBody>
                        <OtpForm
                            onFormSubmit={e => {
                                e.preventDefault();
                                verifyOtpCode({ email, otp });
                            }}
                            rootProps={{ spacing: 4 }}
                            label={content('forms.otp.fields.otp.label')}
                            otpCount={6}
                            disclaimer={content(
                                'forms.login.fields.email.helper',
                                'Terms of Use and Privacy Policy',
                                (match, i) => (
                                    <span key={i}>
                                        <RouteLink
                                            to={
                                                AppConfig.termsOfUseUrl || AppRoutes.LOGIN
                                            }
                                            textDecor="underline"
                                            isExternal={true}
                                        >
                                            Terms of Use
                                        </RouteLink>{' '}
                                        and{' '}
                                        <RouteLink
                                            to={
                                                AppConfig.privacyPolicyUrl ||
                                                AppRoutes.LOGIN
                                            }
                                            textDecor="underline"
                                            isExternal={true}
                                        >
                                            Privacy Policy
                                        </RouteLink>
                                    </span>
                                )
                            )}
                            buttonDisabled={
                                otp.length < 6 || otp.length > 6 || invalidOtp
                            }
                            buttonLabel={'Continue'}
                            isInvalid={invalidOtp}
                            isLoading={isFetchingAccount || isVerifyingOtp}
                            onChange={str => {
                                setOtp(str);
                                setInvalidOtp(false);
                            }}
                            onComplete={() => {
                                if (mode === 'register') {
                                    window.dataLayer.push({
                                        event: 'SecurityAdvisor_DL_Event Registration - Direct Access Email OTP',
                                        System: 'Security Advisor',
                                        Partner: AppConfig.carrier,
                                        Program: AppConfig.program,
                                        ID: '',
                                        Module: 'Email OTP',
                                        MainSubscriberEmail: email,
                                    });
                                } else {
                                    window.dataLayer.push({
                                        event: 'SecurityAdvisor_DL_Event Login OTP',
                                        System: 'Security Advisor',
                                        Partner: AppConfig.carrier,
                                        Program: AppConfig.program,
                                        ID: '',
                                        Module: 'Login OTP',
                                        MainSubscriberEmail: email,
                                    });
                                }
                                console.log('complete');
                            }}
                        />
                    </CardBody>
                </Card>
            </Box>
        </FullWidth>
    );
};

export default OtpPage;
