import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';
import * as React from 'react';

const CloseIconSvg: React.FC<IconProps> = ({ w, h, ...restProps }) => (
    <Icon
        width={w}
        height={w}
        fill="none"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
    >
        <circle cx={24} cy={24} r={24} fill="#333F48" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.467 16.077a.5.5 0 0 0-.707-.707L24 23.13l-7.76-7.76a.5.5 0 1 0-.707.707l7.76 7.76L15.53 31.6a.5.5 0 0 0 .707.707L24 24.544l7.762 7.762a.5.5 0 0 0 .707-.707l-7.762-7.762 7.76-7.76Z"
            fill="#fff"
        />
        <path
            d="m32.467 16.077.354.353-.354-.353ZM24 23.13l-.354.354.354.353.354-.354L24 23.13Zm-7.76-7.76-.354.353.354-.353Zm-.707.707.353-.354-.353.354Zm7.76 7.76.353.354.354-.354-.354-.354-.353.354Zm-7.055 8.469-.353-.354.353.354ZM24 24.544l.354-.353-.354-.354-.354.354.354.353Zm7.762 7.762.353-.354-.353.354Zm.707-.707.353-.354-.353.354Zm-7.762-7.762-.353-.354-.354.354.354.354.353-.354Zm7.407-8.114.707.707a1 1 0 0 0 0-1.414l-.707.707Zm0 0 .707-.707a1 1 0 0 0-1.414 0l.707.707Zm-7.76 7.76 7.76-7.76-.707-.707-7.76 7.76.707.707Zm-8.468-7.76 7.76 7.76.708-.707-7.76-7.76-.708.707Zm0 0 .707-.707a1 1 0 0 0-1.414 0l.707.707Zm0 0-.707-.707a1 1 0 0 0 0 1.414l.707-.707Zm7.76 7.76-7.76-7.76-.707.707 7.76 7.76.707-.707Zm-7.761 8.47 7.761-7.762-.707-.707-7.761 7.761.707.707Zm0 0-.707-.708a1 1 0 0 0 0 1.414l.707-.707Zm0 0-.707.706a1 1 0 0 0 1.414 0l-.707-.707Zm7.761-7.762-7.761 7.761.707.707 7.762-7.761-.708-.707Zm8.47 7.761-7.762-7.761-.708.707 7.762 7.761.707-.707Zm0 0-.708.707a1 1 0 0 0 1.414 0l-.707-.707Zm0 0 .706.707a1 1 0 0 0 0-1.414l-.707.707Zm-7.762-7.761 7.761 7.761.707-.707-7.761-7.762-.707.708Zm7.76-8.468-7.76 7.76.707.708 7.76-7.76-.707-.708Z"
            fill="#fff"
        />
    </Icon>
);

export default CloseIconSvg;
