import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const HappyIcon: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
        <path
            d="M29.74 16.26c0 7.708-6.129 13.397-13.74 13.397-7.61 0-13.739-5.69-13.739-13.397 0-7.741 6.162-13.998 13.74-13.998 7.577 0 13.738 6.257 13.738 13.998Z"
            stroke="#111"
            strokeWidth={1.2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m19.328 11.62 1.785 1.577 1.785-1.578M9.44 11.62l1.785 1.577 1.785-1.578M9.593 18.971c.224 2.507 2.126 5.412 6.222 5.412 4.429 0 6.509-2.557 6.724-5.412a.472.472 0 0 0-.482-.5H10.071a.467.467 0 0 0-.478.5Z"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.505 12.013c.102-1.065-.106-1.376-.647-1.428-.542-.053-1.695 1.203-1.695 1.203s-1.147-1.293-1.69-1.345c-.542-.052-.854.184-.957 1.248-.076.795.905 1.44 1.552 1.96.582.47.833.854.833.854.398-.287.503-.528.998-.82.575-.34 1.518-.764 1.606-1.672ZM8.679 11.974c-.077-1.08.17-1.275.713-1.314.542-.039 1.634 1.147 1.634 1.147s1.177-1.278 1.72-1.317c.544-.039.851.208.928 1.288.057.807-.94 1.434-1.598 1.944-.594.459-.854.841-.854.841-.39-.3-.49-.547-.977-.855-.567-.359-1.5-.813-1.566-1.734Z"
            stroke="#111"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);

export default HappyIcon;
