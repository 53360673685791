import { IconProps, Icon } from '@chakra-ui/react';
import * as React from 'react';

const SendIconSvg: React.FC<IconProps> = ({ w, h, ...restProps }) => (
    <Icon
        width={w}
        height={h}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.449 12.894 5.057 20.858c-.72.35-1.58.032-1.92-.709A1.52 1.52 0 0 1 3 19.516v-4.127c0-.998.721-1.84 1.683-1.963l7.248-.935a.492.492 0 0 0 .417-.553.488.488 0 0 0-.417-.429l-7.248-.935C3.72 10.45 3 9.61 3 8.611V4.484C3 3.664 3.645 3 4.441 3c.213 0 .423.048.616.142l16.392 7.963a1.002 1.002 0 0 1 0 1.79Z"
                fill="#B0B0B0"
            />
        </g>
    </Icon>
);

export default SendIconSvg;
