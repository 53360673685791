import React from 'react';
import { loginEmail } from '../entities/session';
import useContent from '../hooks/useContent';
import OtpPage from './OtpPage';

const LoginOtpPage: React.FC = () => {
    const content = useContent();
    const email = loginEmail.use();

    return (
        <OtpPage
            id={'login-otp-page'}
            title={content('forms.otp.header.title')}
            confirmation={content(
                'forms.otp.header.confirmation',
                '{email}',
                (match, i) => {
                    return (
                        <span key={i}>
                            <br />
                            <strong>{email}</strong>
                        </span>
                    );
                }
            )}
            instruction={content('forms.otp.header.instruction')}
            mode={'login'}
        />
    );
};

export default LoginOtpPage;
