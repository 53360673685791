import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonProps,
    Flex,
    FlexProps,
    Heading,
    HeadingProps,
    Text,
    TextProps,
} from '@chakra-ui/react';
import React from 'react';

export const WizardCardBottom: React.FC<FlexProps> = ({ children, ...props }) => (
    <Flex
        borderBottomRadius="8px"
        background="white"
        border="1px solid"
        borderColor="grayscale.550"
        p={6}
        pt={4}
        flexDir="column"
    >
        {children}
    </Flex>
);

export const WizardCardBottomHeading: React.FC<HeadingProps> = ({
    children,
    ...props
}) => (
    <Heading fontSize="xl" fontWeight={700} mb={4} {...props}>
        {children}
    </Heading>
);

export const WizardCardActions: React.FC<FlexProps> = ({ children, ...props }) => (
    <Flex gap={4} mt={4}>
        {children}
    </Flex>
);

export const WizardCardPolicy: React.FC<TextProps> = props => (
    <Text color="grayscale.200" fontSize="sm" pt={2} {...props}>
        © Asurion 1992-2023. All Rights Reserved.{' '}
        <Box as="span" textDecor="underline">
            Privacy Policy
        </Box>
    </Text>
);

export const WizardCardStepperActions: React.FC<{
    prevButtonProps?: ButtonProps & { text?: string };
    nextButtonProps?: ButtonProps & { text?: string };
}> = ({ prevButtonProps, nextButtonProps }) => {
    return (
        <WizardCardActions>
            <Button
                variant="outline"
                colorScheme="gray"
                leftIcon={<ArrowBackIcon />}
                {...prevButtonProps}
            >
                {prevButtonProps?.text || 'Back'}
            </Button>
            <Button rightIcon={<ArrowForwardIcon />} {...nextButtonProps}>
                {nextButtonProps?.text || 'Next'}
            </Button>
        </WizardCardActions>
    );
};
