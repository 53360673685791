import { CheckIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, HStack, Spacer, Spinner, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import AccountAffectedWorldwideSvg from '../../assets/AccountAffectedWorldwideSvg';
import CalendarSvg from '../../assets/CalendarSvg';
import EyeClosedSvg from '../../assets/EyeClosedSvg';
import EyeOpenSvg from '../../assets/EyeOpenSvg';
import { ASSET_CRITERIA } from '../../config/config';
import { IAssetTypeUnmaskedResponse, NullableVar } from '../../config/interface';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import useLogout from '../../hooks/useLogout';
import {
    fetchBreachResolutionSteps,
    markedResolve,
    unmaskedValue,
} from '../../services/apiService';
import { clippedString, getResponseError, isMobileScreen } from '../../utils/helpers';
import BreachAssetTypeNextSteps from './BreachAssetTypeNextSteps';

const useBreachResolutionSteps = (asset: string) =>
    // TODO: Move query keys to config
    useQuery(['breach', 'resolutionSteps', 'asset'], {
        queryFn: () => fetchBreachResolutionSteps(asset),
        enabled: !!asset,
    });

const BreachAssetTypeDisplay = ({ email, asset, item, mode, onResolved }) => {
    const content = useContent();
    const doLogout = useLogout();
    const { field } = getPageStyles('BreachDetailsPage');
    const [selectedDocumentId, setSelectedDocumentId] = useState<number>(0);
    const [isResolved, setIsResolve] = useState<boolean>(false);
    const [unmasked, setUnmasked] = useState<NullableVar<string>>(null);
    const { documentId, maskedItem, accountsAffected, dateReported, details } = item;
    const clippedLength = isMobileScreen() ? 15 : 70;

    const fieldValues = [
        {
            key: 'maskedItem',
            label: `compromised ${asset}`,
            value: maskedItem,
            icon: <EyeClosedSvg />,
        },
        {
            key: 'accountsAffected',
            label: `accounts affected`,
            icon: <AccountAffectedWorldwideSvg />,
            value: accountsAffected,
        },
        {
            key: 'dateReported',
            label: `date reported`,
            icon: <CalendarSvg />,
            value: dateReported,
        },
        { key: 'details', label: `details`, value: details },
    ];

    const {
        data: resolutionSteps,
        error: resolutionStepsError,
        isFetching: fetchingResolutionSteps,
    } = useBreachResolutionSteps(asset);

    // resolution
    if (!fetchingResolutionSteps && resolutionStepsError) {
        const { status } = getResponseError(resolutionStepsError);
        if (status === 401) {
            doLogout();
        }
    }

    const {
        data: unmaskedData,
        error: unmaskedError,
        isFetching: unmaskedFetching,
    } = useQuery<IAssetTypeUnmaskedResponse>(
        ['unmasked', email, asset, selectedDocumentId],
        () => unmaskedValue({ email, asset, documentId: selectedDocumentId }),
        {
            enabled: email && email && asset && selectedDocumentId ? true : false,
        }
    );

    const { mutate: markAsResolve, isLoading: resolvedFetching } = useMutation({
        mutationFn: markedResolve,
        onSuccess: () => {
            // resolving
            setIsResolve(true);
        },
        onError: resolvedError => {
            const { status } = getResponseError(resolvedError);
            if (status === 401) {
                doLogout();
            }
        },
    });

    const ListBox = styled(Box)`
        li {
            font-size: 16px;
            font-weight: 400;
        }
    `;

    useEffect(() => {
        if (isResolved === true) {
            onResolved(true);
        }

        // unmasking
        if (unmaskedData && unmasked === null) {
            setUnmasked(unmaskedData.unmaskedItem);
        }

        if (!unmaskedFetching && unmaskedError) {
            const { status } = getResponseError(unmaskedError);
            if (status === 401) {
                doLogout();
            }
        }
    }, [
        isResolved,
        onResolved,
        unmaskedData,
        unmasked,
        unmaskedError,
        unmaskedFetching,
        doLogout,
    ]);

    return (
        <Flex id={`breachpage-asset-type-display-${documentId}`} direction={'column'}>
            {fieldValues &&
                fieldValues.map((item, i) => {
                    const { key, label, icon, value } = item;
                    return (
                        <Box key={`field-for-${documentId}-${key}-${i}`}>
                            <Text {...field.label}>{label}</Text>
                            {key === 'maskedItem' ? (
                                <Box {...field.maskedBox}>
                                    <HStack alignItems={'center'}>
                                        {!unmasked && (
                                            <Text {...field.value}>
                                                {clippedString(value, clippedLength)}
                                            </Text>
                                        )}
                                        {unmasked && (
                                            <Text {...field.value}>
                                                {clippedString(unmasked, clippedLength)}
                                            </Text>
                                        )}
                                        <Spacer />
                                        <Box
                                            cursor={'pointer'}
                                            onClick={() => {
                                                if (unmasked === null) {
                                                    setSelectedDocumentId(documentId);
                                                } else {
                                                    setSelectedDocumentId(0);
                                                    setUnmasked(null);
                                                }
                                            }}
                                        >
                                            {unmaskedFetching ? (
                                                <Spinner variant={'miniLoader'} />
                                            ) : unmasked ? (
                                                <Box mt={'2px'}>
                                                    <EyeOpenSvg />
                                                </Box>
                                            ) : (
                                                <Box>{icon}</Box>
                                            )}
                                        </Box>
                                    </HStack>
                                </Box>
                            ) : (
                                <Box>
                                    <HStack alignItems={'center'}>
                                        {icon && <>{icon}</>}
                                        <Text {...field.value}>{value}</Text>
                                    </HStack>
                                </Box>
                            )}
                        </Box>
                    );
                })}

            <Box>
                <Flex direction={'column'} gap={2} mt={'24px'}>
                    <Box {...field.guideBox}>
                        <Text>{content('breach.stepguide')}</Text>
                    </Box>

                    <ListBox {...field.stepText}>
                        {resolutionSteps?.message && (
                            <Text>{resolutionSteps?.message}</Text>
                        )}
                        {resolutionSteps?.steps && (
                            <BreachAssetTypeNextSteps
                                data={resolutionSteps.steps}
                                mt={4}
                            />
                        )}
                    </ListBox>
                </Flex>
            </Box>

            {mode === ASSET_CRITERIA.EXPOSED && (
                <Divider orientation="horizontal" mt={'10px'} />
            )}
            {mode === ASSET_CRITERIA.EXPOSED && (
                <Box mt={'24px'}>
                    <Box w={'150px'} m={'0 auto'}>
                        {!resolvedFetching ? (
                            <Box
                                {...field.resolveTick}
                                onClick={() => {
                                    markAsResolve({ email, asset, documentId });
                                }}
                            >
                                {isResolved && <CheckIcon />}
                            </Box>
                        ) : (
                            <Spinner variant={'miniLoader'} {...field.resolveSpinner} />
                        )}
                        <Box float={'right'}>
                            <Text {...field.resolve}>Mark as resolved</Text>
                        </Box>
                    </Box>
                </Box>
            )}
        </Flex>
    );
};

export default BreachAssetTypeDisplay;
