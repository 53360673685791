import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import ProfileEmailsList from '../components/profile/ProfileEmailsList';
import ProfileWelcome from '../components/profile/ProfileWelcome';
import { getPageStyles } from '../config/theme';
import { deleteSecondaryEmail, fetchProfile } from '../services/apiService';
import { account } from '../entities/session';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getResponseError } from '../utils/helpers';
import { IAccountDetails, NullableObj, NullableVar } from '../config/interface';
import useLogout from '../hooks/useLogout';
import ConfirmDialog from '../components/common/ConfirmDialog';

const ProfilePage = () => {
    const { main } = getPageStyles('ProfilePage');
    const doLogout = useLogout();
    const accountInfo = account.use();
    const { primaryEmail } = accountInfo;

    const [profile, setProfile] = useState<NullableObj<IAccountDetails>>();
    const [showConfirmEmailDelete, setShowConfirmEmailDelete] = useState<boolean>(false);
    const [emailToDelete, setEmailToDelete] = useState<NullableVar<string>>();

    const { mutate: deleteEmail, isLoading: isDeletingSecondaryEmail } = useMutation({
        mutationFn: deleteSecondaryEmail,
        onSuccess: data => {
            refetchProfile();
        },
        onError: error => {
            const { status } = getResponseError(error);
            if (status === 401) {
                doLogout();
            }
        },
    });

    const { refetch: refetchProfile } = useQuery<IAccountDetails>(
        ['profile'],
        () => fetchProfile({ email: primaryEmail.email }),
        {
            onSuccess: data => {
                setProfile(data);
                account.set(data);
            },
            onError: error => {
                const { status } = getResponseError(error);
                if (status === 401) {
                    doLogout();
                }
            },
        }
    );

    const onToggleMonthlyReportSuccess = useCallback(() => {
        refetchProfile();
    }, [refetchProfile]);

    const onToggleConfirmEmailDelete = useCallback(email => {
        setShowConfirmEmailDelete(true);
        setEmailToDelete(email);
    }, []);

    return (
        <Box id={'profile-page'} {...main}>
            <ConfirmDialog
                title={'Delete email'}
                isOpen={showConfirmEmailDelete}
                onClose={() => {
                    setShowConfirmEmailDelete(false);
                }}
                onNo={() => {
                    setShowConfirmEmailDelete(false);
                }}
                onYes={() => {
                    setShowConfirmEmailDelete(false);
                    deleteEmail({ email: emailToDelete || '' });
                }}
            >
                <Text>
                    Are you sure you want to delete <b>{emailToDelete}</b> ?
                </Text>
            </ConfirmDialog>
            <Flex direction={'column'} gap={0}>
                <ProfileWelcome />
                <ProfileEmailsList
                    profile={profile || accountInfo}
                    onToggleMonthlyReportSuccess={onToggleMonthlyReportSuccess}
                    onToggleConfirmEmailDelete={onToggleConfirmEmailDelete}
                    isDeletingSecondaryEmail={isDeletingSecondaryEmail}
                />
            </Flex>
        </Box>
    );
};

export default ProfilePage;
