import * as React from 'react';
import {
    Box,
    Flex,
    Image,
    VStack,
    Heading,
    Button,
    Icon,
    HStack,
} from '@chakra-ui/react';
import Carousel from 'nuka-carousel';
import { ACTIONS, StoreState } from 'react-joyride';
import SmartScanOnboardingLogo from '../../assets/logos/SmartScanOnboardingLogo';

const { useState } = React;

export type OnboaringCarouselProps = {
    steps: Array<any>;
    callback?: (state: Pick<StoreState, 'action'>) => void;
};

const CircleIcon = props => (
    <Icon viewBox="0 0 200 200" {...props}>
        <path
            fill="currentColor"
            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
    </Icon>
);

export const OnboardingCarousel: React.FC<OnboaringCarouselProps> = ({
    steps,
    callback = () => {},
}) => {
    const [current, setCurrent] = useState<number>(0);
    return (
        <Carousel
            afterSlide={index => setCurrent(index)}
            defaultControlsConfig={{
                pagingDotsStyle: {
                    display: 'none',
                },
                prevButtonStyle: {
                    display: 'none',
                },
                nextButtonStyle: {
                    display: 'none',
                },
            }}
        >
            {steps.map((step: any, i: number) => {
                return (
                    <Flex
                        h={'100vh'}
                        key={`step-${i}`}
                        alignItems={'flex-start'}
                        justifyContent={'center'}
                        position={'relative'}
                    >
                        <VStack
                            h={'100vh'}
                            justifyContent={'space-between'}
                            pb={200}
                            pt={'80px'}
                        >
                            <SmartScanOnboardingLogo />
                            <Image src={step.image} w={228} h={'auto'} />
                        </VStack>
                        <VStack
                            h={'320px'}
                            w={'100%'}
                            alignSelf={'flex-end'}
                            position={'absolute'}
                            px={'16px'}
                            pt={'36px'}
                            borderTopLeftRadius={'30px'}
                            borderTopRightRadius={'30px'}
                            bgColor="white"
                        >
                            <Image src={step.icon} width={6} height={6} />
                            <Heading fontSize={24}>{step.title}</Heading>
                            <Box h={100} textAlign="center">
                                {step.content}
                            </Box>
                            <HStack>
                                {steps.map((step, j) => {
                                    return (
                                        <CircleIcon
                                            boxSize={'6px'}
                                            color={
                                                current === j ? 'black' : 'grayscale.690'
                                            }
                                            opacity={current === j ? 1 : 0.2}
                                        />
                                    );
                                })}
                            </HStack>
                            <Button
                                onClick={() => callback({ action: ACTIONS.CLOSE })}
                                disabled={current < steps.length - 1}
                            >
                                Let's Begin
                            </Button>
                        </VStack>
                    </Flex>
                );
            })}
        </Carousel>
    );
};
