import * as React from 'react';

const AssetDriversLicenseSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m10 22.2 4-12h20l4 12m-28 0h28m-28 0v12m28-12v12m-28 0v4h2v-4m-2 0h2m26 0v4h-2v-4m2 0h-2m-24 0h24"
            stroke="#111"
        />
        <circle cx={16} cy={28.2} r={2} fill="#111" />
        <circle cx={32} cy={28.2} r={2} fill="#111" />
    </svg>
);

export default AssetDriversLicenseSvg;
