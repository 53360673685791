import { LinkProps, Link } from '@chakra-ui/react';
import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom';
import React from 'react';

const RouteLink: React.FC<LinkProps & RouterLinkProps> = ({ children, ...props }) => {
    const { isExternal } = props;

    return (
        <Link
            as={RouterLink}
            textDecoration={'none'}
            _hover={{ textDecoration: 'none' }}
            {...props}
            onClick={e => {
                if (isExternal) {
                    window.open(props.to as string, '_blank');
                    e.preventDefault();
                }
            }}
        >
            {children}
        </Link>
    );
};

export default RouteLink;
