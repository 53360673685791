import { Icon, IconProps } from '@chakra-ui/react';

const DeleteRecordIcon: React.FC<IconProps> = props => (
    <Icon
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 3.5v2h6v2H3v-2h6v-2h6ZM11 12.5H9v6h2v-6ZM13 12.5h2v6h-2v-6Z"
            fill="#111"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 22.5c-1.103 0-2-.897-2-2v-12h14v12c0 1.103-.897 2-2 2H7Zm10-12H7v10h10.001L17 10.5Z"
            fill="#111"
        />
    </Icon>
);

export default DeleteRecordIcon;
