import moment from 'moment';
import { AppConfig, AppPublicRoutes } from '../config/config';

export const emailSplitter = email => {
    return email ? email.split('@') : [];
};

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const isMobileScreen = () => {
    return window.innerWidth <= 430 ? true : false;
};
export const noop = () => {};

export const getResponseError = error => {
    const { response } = error || { response: { status: '', data: { message: '' } } };
    const { status, data } = response;
    const { message } = data;

    return {
        status,
        message,
    };
};

export const isValidEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const lastScanFormat = lastScanDate => {
    return lastScanDate
        ? moment(new Date(lastScanDate).getTime()).format('DD MMM YYYY')
        : '';
};

export const iterateStorage = storage => {
    for (var a in storage) {
        if (typeof storage[a] !== 'function' && a.indexOf(AppConfig.storagePrefix) > -1) {
            delete storage[a];
        }
    }
};

export const triggerSignOut = () => {
    iterateStorage(localStorage);
    iterateStorage(sessionStorage);

    window.location.href = createUrl('');
};

export const formatAssetName = (asset: string) => {
    return asset.replaceAll('_', ' ');
};

export const removeEmptyProps = <T extends Record<string, unknown>>(obj: T) => {
    const entries = Object.entries(obj).filter(
        ([, value]) => value !== null && value !== undefined
    );
    return Object.fromEntries(entries);
};

export const getQueryString = params =>
    Object.entries(removeEmptyProps(params))
        .map(
            ([key, value]) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        )
        .join('&');

export const isFeatureEnabled = featureKey => {
    const disabled = process.env.REACT_APP_DISABLED_FEATURES;
    return disabled && disabled.indexOf(featureKey) > -1 ? false : true;
};

export const isPublicPage = pathname => {
    return AppPublicRoutes.indexOf(pathname) > -1 ? true : false;
};

export const createUrl = pathname => {
    return `${AppConfig.baseRoute}${pathname}`;
};

export const toAnchorTag = (input: string): string => {
    const trimmedInput = input.trim().toLowerCase();
    const hyphenatedInput = trimmedInput.replace(/[_\s]+/g, '-');
    const words = hyphenatedInput.split('-');

    let truncatedInput = '';
    let lengthSoFar = 0;

    for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (lengthSoFar + word.length + (i > 0 ? 1 : 0) <= 32) {
            truncatedInput += (i > 0 ? '-' : '') + word;
            lengthSoFar += word.length + (i > 0 ? 1 : 0);
        } else {
            break;
        }
    }
    return truncatedInput;
};

export const removeNumberedSubstring = (str: string): string => {
    const regex = /\d+ \/ /g;
    return str.replace(regex, '');
};

export const clippedString = (str: string, len: number): string => {
    return str.length > len ? `${str.substring(0, len)}...` : str;
};
