import * as React from 'react';

const BreachPageMainIconSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.5 24a19.5 19.5 0 1 0 39 0 19.5 19.5 0 0 0-39 0Z"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.538 25.695A13.574 13.574 0 0 1 30.781 12.25M36.268 29.796a13.567 13.567 0 0 1-22.656 2.928M35.748 17.217a13.49 13.49 0 0 1 1.712 5.087"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.76 30.345a7.631 7.631 0 0 1 6.783-13.54M31.441 22.304a7.63 7.63 0 0 1-4.05 8.533M24 24l11.842-11.842M23.576 30.783a.424.424 0 0 1 .424.424M23.15 31.207a.424.424 0 0 1 .425-.424"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.575 31.63a.424.424 0 0 1-.424-.424M24 31.206a.424.424 0 0 1-.424.425M37.14 25.696a.424.424 0 0 1 .424.424"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M36.717 26.12a.424.424 0 0 1 .424-.424M37.14 26.544a.424.424 0 0 1-.423-.424M37.564 26.12a.424.424 0 0 1-.424.424M32.743 37.565a.424.424 0 0 1 .424.424M32.318 37.99a.424.424 0 0 1 .424-.425"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.742 38.413a.424.424 0 0 1-.424-.424M33.167 37.99a.424.424 0 0 1-.424.423M11.706 29.087a.424.424 0 0 1 .424.424M11.281 29.51a.424.424 0 0 1 .425-.423M11.706 29.935a.424.424 0 0 1-.425-.424"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.13 29.51a.424.424 0 0 1-.424.425M12.395 12.13a.424.424 0 0 1 .424.424M11.97 12.554a.424.424 0 0 1 .424-.424M12.394 12.978a.424.424 0 0 1-.424-.424M12.819 12.554a.424.424 0 0 1-.425.424"
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default BreachPageMainIconSvg;
