import { Box } from '@chakra-ui/react';
import React from 'react';
import ProfileEmailDisplay from './ProfileEmailDisplay';

const ProfileEmailsListMobile = ({
    primaryEmail,
    secondaryEmails,
    profile,
    onToggleMonthlyReportSuccess,
    onToggleConfirmEmailDelete,
    isDeletingSecondaryEmail,
}) => {
    return (
        <Box pt={'16px'} display={{ base: 'block', md: 'none' }}>
            <ProfileEmailDisplay
                isDesktop={false}
                {...primaryEmail}
                {...profile}
                isPrimary={true}
                isChecked={primaryEmail.monthlyScanSubscription || false}
                onToggleMonthlyReportSuccess={onToggleMonthlyReportSuccess}
                onToggleConfirmEmailDelete={onToggleConfirmEmailDelete}
                isDeletingSecondaryEmail={isDeletingSecondaryEmail}
            />

            {secondaryEmails?.map((info, i) => {
                return (
                    <ProfileEmailDisplay
                        isDesktop={false}
                        {...info}
                        isPrimary={false}
                        firstName={profile.firstName}
                        lastName={profile.lastName}
                        key={`email-card-${i}`}
                        isChecked={info.monthlyScanSubscription || false}
                        onToggleMonthlyReportSuccess={onToggleMonthlyReportSuccess}
                        onToggleConfirmEmailDelete={onToggleConfirmEmailDelete}
                        isDeletingSecondaryEmail={isDeletingSecondaryEmail}
                    />
                );
            })}
        </Box>
    );
};

export default ProfileEmailsListMobile;
