import * as React from 'react';
const EyeOpenSvg = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={12} fill="none" {...props}>
        <path
            fill="#333F48"
            fillRule="evenodd"
            d="M2.025 6C3.423 8.193 5.617 10.2 9 10.2c3.384 0 5.577-2.007 6.975-4.2C14.577 3.807 12.384 1.8 9 1.8 5.617 1.8 3.423 3.807 2.025 6Zm-1.22-.31C2.3 3.2 4.863.6 9 .6s6.7 2.6 8.195 5.09l.185.31-.185.308C15.7 8.798 13.138 11.4 9 11.4S2.3 8.799.806 6.308L.62 6l.186-.31ZM9 3.025a2.976 2.976 0 1 0 0 5.952 2.976 2.976 0 0 0 0-5.952Z"
            clipRule="evenodd"
        />
    </svg>
);
export default EyeOpenSvg;
