import {
    Flex,
    FlexProps,
    Heading,
    HeadingProps,
    Progress,
    ProgressProps,
} from '@chakra-ui/react';
import { Document } from '@contentful/rich-text-types';
import React, { ReactElement } from 'react';
import renderDocument from '../../../utils/renderDocument';

// TODO: Split into files

interface WizardCardTopProps extends FlexProps {
    title?: string;
    description?: Document;
    step?: string | ReactElement;
    progress?: number;
}

export const WizardCardTop: React.FC<WizardCardTopProps> = ({
    children,
    title,
    description,
    step,
    progress,
    ...props
}) => (
    <Flex
        flexDir="column"
        borderTopRadius="8px"
        background="linear-gradient(91.62deg, var(--chakra-colors-grayscale-750) 0%, var(--chakra-colors-grayscale-700) 100%) var(--chakra-colors-grayscale-700)"
        p={6}
        pb={4}
        color="white"
        pos="relative"
        {...props}
    >
        {title && <WizardCardTopHeading>{title}</WizardCardTopHeading>}
        {description && renderDocument(description)}
        {step && <WizardCardStep>{step}</WizardCardStep>}
        {progress && <WizardCardProgress value={progress} />}
        {children}
    </Flex>
);

export const WizardCardTopHeading: React.FC<HeadingProps> = ({ children, ...props }) => (
    <Heading fontSize="2xl" fontWeight={700} mb={2} {...props}>
        {children}
    </Heading>
);

export const WizardCardProgress: React.FC<ProgressProps> = props => (
    <Progress
        pos="absolute"
        bottom={0}
        left={0}
        colorScheme="progress"
        height={1}
        w="full"
        {...props}
    />
);

export const WizardCardStep: React.FC<HeadingProps> = ({ children, ...props }) => (
    <Heading fontSize="sm" mt={5} fontWeight={700} {...props}>
        {children}
    </Heading>
);
