import * as React from 'react';

const MobileSignoutSvg = props => (
    <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.565 5.696V2.043c0-.576-.467-1.043-1.043-1.043H2.043C1.467 1 1 1.467 1 2.043v21.913C1 24.533 1.467 25 2.043 25h11.479c.576 0 1.043-.467 1.043-1.044v-3.652M25 13H5.174M19.783 18.218 25 13l-5.217-5.217"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default MobileSignoutSvg;
