import * as React from 'react';

const WarningRedSvg = props => (
    <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.998 9.363A8.117 8.117 0 0 1 9 17.499 7.88 7.88 0 0 1 1 9.64a8.117 8.117 0 0 1 8-8.138 7.881 7.881 0 0 1 7.998 7.862Z"
            fill="#DB1B1B"
            stroke="#DB1B1B"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 10.958v-5.09M8.993 12.413a.178.178 0 0 0-.176.185c.004.1.085.178.184.178v0a.179.179 0 0 0 .176-.185.184.184 0 0 0-.178-.178h-.004"
            stroke="#fff"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default WarningRedSvg;
