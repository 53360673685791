import { IconProps, Icon } from '@chakra-ui/react';
import * as React from 'react';

const AttachFileIconSvg: React.FC<IconProps> = ({ w, h, ...restProps }) => (
    <Icon
        width={w}
        height={w}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
    >
        <g>
            <path
                d="m4.946 13.444 6.27-6.339a3.3 3.3 0 0 1 4.702 0 3.388 3.388 0 0 1 0 4.755l-7.445 7.527a1.65 1.65 0 0 1-2.351 0 1.694 1.694 0 0 1 0-2.377l6.661-6.735a.565.565 0 0 0 0-.793.55.55 0 0 0-.784 0l-6.661 6.736a2.823 2.823 0 0 0 0 3.962 2.75 2.75 0 0 0 3.918 0l7.445-7.528a4.518 4.518 0 0 0 0-6.34 4.399 4.399 0 0 0-6.27 0l-6.269 6.34a.565.565 0 0 0 0 .792.55.55 0 0 0 .784 0Z"
                fill="#B0B0B0"
                stroke="#B0B0B0"
            />
        </g>
    </Icon>
);

export default AttachFileIconSvg;
