import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import FullWidth from '../components/layouts/FullWidth';
import Register from '../components/registration/Register';
import { AppRoutes, REGISTRATION_STATUS } from '../config/config';
import { NullableVar } from '../config/interface';
import { authToken, loginEmail, registerEmail } from '../entities/session';
import useErrorToast from '../hooks/useErrorToast';
import { updatePrimaryUser } from '../services/apiService';

const RegistrationPage = () => {
    const { mdn } = useParams<{ mdn: string }>();

    const [initRegister, setInitRegister] = useState<NullableVar<boolean>>(null);

    const errToast = useErrorToast();

    const { mutate: doLogin, isLoading: loginFetching } = useMutation({
        mutationFn: updatePrimaryUser,
        onSuccess: data => {
            const { success, registrationStatus } = data;
            if (
                success &&
                registrationStatus === REGISTRATION_STATUS.ALREADY_EXISTING_EMAIL
            ) {
                setInitRegister(success);
            } else if (
                success &&
                registrationStatus === REGISTRATION_STATUS.NOT_EXISTING_EMAIL
            ) {
                let objError: any = {
                    message: 'The email you entered is not yet enrolled.',
                };
                errToast(objError);
            } else if (success && !registrationStatus) {
                setInitRegister(success);
            }
        },
        onError: (error: AxiosError<Error>) => {
            let objError: any = {
                message:
                    'An error occurred while registering your email. Please try again later.',
            };
            errToast(objError);
        },
    });

    return (
        <FullWidth id={'registration-page'}>
            {/* {initRegister === true && <Navigate to={AppRoutes.REGISTRATION_OTP} />} */}
            <Header authorized={false}></Header>

            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Register
                    onRegister={email => {
                        authToken.set(null);
                        setInitRegister(null);
                        registerEmail.set(email);
                        loginEmail.set(email);
                        doLogin({ mdn: mdn || '', email });
                    }}
                    isAuthenticating={loginFetching}
                />
            </Box>
        </FullWidth>
    );
};

export default RegistrationPage;
