import {
    Box,
    Flex,
    HStack,
    Spacer,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import AssetAddressesSvg from '../../assets/AssetAddressesSvg';
import AssetBankAccountsSvg from '../../assets/AssetBankAccountsSvg';
import AssetCreditCardsSvg from '../../assets/AssetCreditCardsSvg';
import AssetDriversLicenseSvg from '../../assets/AssetDriversLicenseSvg';
import AssetEmailSvg from '../../assets/AssetEmailSvg';
import AssetFullnamesSvg from '../../assets/AssetFullnamesSvg';
import AssetPassportSvg from '../../assets/AssetPassportSvg';
import AssetPasswordsSvg from '../../assets/AssetPasswordsSvg';
import AssetPhoneSvg from '../../assets/AssetPhoneSvg';
import AssetUsernamesSvg from '../../assets/AssetUsernamesSvg';
import { AppRoutes } from '../../config/config';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import RouteLink from '../common/RouteLink';

const BreachDataDisplay = ({ assets, selectedEmail }) => {
    const content = useContent();
    const { display } = getPageStyles('BreachPage');

    const icons = {
        USERNAME: <AssetUsernamesSvg />,
        PASSWORD: <AssetPasswordsSvg />,
        EMAIL: <AssetEmailSvg />,
        CREDIT_CARD: <AssetCreditCardsSvg />,
        BANK_ACCOUNT: <AssetBankAccountsSvg />,
        PHONE_NUMBER: <AssetPhoneSvg />,
        PASSPORT_NUMBER: <AssetPassportSvg />,
        FULLNAME: <AssetFullnamesSvg />,
        DRIVERS_LICENSE: <AssetDriversLicenseSvg />,
        ADDRESS: <AssetAddressesSvg />,
    };

    return (
        <Flex direction={'column'} gap={2} {...display.root}>
            <Text {...display.title}>{content('breach.letsresolve')}</Text>
            <Text {...display.subtitle1}>{content('breach.noteresolve')}</Text>

            <Wrap>
                {assets &&
                    Object.keys(assets).map((category, categoryIndex) => {
                        const subcategory = assets[category];
                        return (
                            <WrapItem
                                id={`breachpage-display-assets-${categoryIndex}`}
                                key={`breachpage-display-assets-${categoryIndex}`}
                                {...display.categoryRoot}
                            >
                                <Box>
                                    <Text {...display.categoryTitle}>{category}</Text>
                                    <Wrap spacing={{ base: '8px', md: '16px' }}>
                                        {subcategory.map((subItem, subIndex) => {
                                            const {
                                                name: key,
                                                exposed,
                                                resolved,
                                            } = subItem;

                                            const url =
                                                AppRoutes.BREACH_DETAILS.replaceAll(
                                                    ':id',
                                                    selectedEmail.email
                                                ).replaceAll(':asset', key.toLowerCase());

                                            const name = subItem?.name
                                                ?.toLowerCase()
                                                .replaceAll('_', ' ');
                                            return (
                                                <WrapItem
                                                    p={0}
                                                    m={0}
                                                    key={`wrap-subcategory-${subIndex}`}
                                                >
                                                    <RouteLink to={encodeURI(url)}>
                                                        <Box
                                                            {...display.subcategoryRoot
                                                                .default}
                                                            {...display.subcategoryRoot[
                                                                exposed > 0
                                                                    ? 'exposed'
                                                                    : 'resolved'
                                                            ]}
                                                        >
                                                            <VStack>
                                                                <Box
                                                                    {...display.subcategoryIcon}
                                                                >
                                                                    {icons[key]}
                                                                </Box>
                                                                <Box>
                                                                    <Text
                                                                        {...display.subcategoryName}
                                                                    >
                                                                        {name}
                                                                    </Text>
                                                                </Box>
                                                                <Box
                                                                    {...display.subcategoryStatusLine}
                                                                >
                                                                    <HStack>
                                                                        <Text
                                                                            {...display.subcategoryStatusText}
                                                                        >
                                                                            Exposed
                                                                        </Text>
                                                                        <Spacer />
                                                                        <Text>
                                                                            {exposed}
                                                                        </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Box
                                                                    {...display.subcategoryStatusLine}
                                                                >
                                                                    <HStack>
                                                                        <Text
                                                                            {...display.subcategoryStatusText}
                                                                        >
                                                                            Resolved
                                                                        </Text>
                                                                        <Spacer />
                                                                        <Text>
                                                                            {resolved}
                                                                        </Text>
                                                                    </HStack>
                                                                </Box>
                                                            </VStack>
                                                        </Box>
                                                    </RouteLink>
                                                </WrapItem>
                                            );
                                        })}
                                    </Wrap>
                                </Box>
                            </WrapItem>
                        );
                    })}
            </Wrap>
        </Flex>
    );
};

export default BreachDataDisplay;
