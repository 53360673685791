import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CloseButton,
} from '@chakra-ui/react';
import * as React from 'react';
import { Step, TooltipRenderProps } from 'react-joyride';
import useContent from '../../../hooks/useContent';

const Tooltip: React.FC<
    TooltipRenderProps & { step: Step & { buttonLabel?: string } }
> = ({
    continuous,
    step: { title, content: stepContent, hideBackButton, buttonLabel },
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
}) => {
    const content = useContent();

    return (
        <Card {...tooltipProps} w={{ base: '343px' }}>
            <CloseButton
                {...closeProps}
                alignSelf={'flex-end'}
                _hover={{ background: 'transparent' }}
                color={'grayscale.800'}
            />
            {title && (
                <CardHeader
                    px={4}
                    pt={0}
                    pb={2}
                    fontWeight={'bold'}
                    fontSize={24}
                    textAlign="center"
                    color={'grayscale.690'}
                >
                    {title}
                </CardHeader>
            )}
            <CardBody textAlign={'center'} py={0} color={'grayscale.690'}>
                {stepContent}
            </CardBody>
            <CardFooter gap={2} px={4} pt={4} pb={6}>
                {hideBackButton === false && (
                    <Button {...backProps}>{content('common.navigation.back')}</Button>
                )}
                {continuous && (
                    <Button _focusVisible={{ outline: 'none' }} {...primaryProps}>
                        {buttonLabel}
                    </Button>
                )}
            </CardFooter>
        </Card>
    );
};

export default Tooltip;
