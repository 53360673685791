import { Box, Circle, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import React from 'react';
import { defaultRenderNodeOptions } from '../../utils/contentfulRenderOptions';

const listRenderer = {
    renderNode: defaultRenderNodeOptions,
};

const headerRenderer = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Text fontSize="lg" whiteSpace="pre-wrap" fontWeight="700" pt={1}>
                {children}
            </Text>
        ),
    },
};

const BreachAssetTypeNextSteps: React.FC<{ data?: Document } & StackProps> = ({
    data,
    ...props
}) => {
    return (
        <Stack spacing={4} {...props}>
            {data?.content && data?.nodeType === `${BLOCKS.OL_LIST}`
                ? data?.content?.map((numberedItem, i) =>
                      numberedItem?.nodeType === `${BLOCKS.LIST_ITEM}` ? (
                          <Stack spacing={1} key={`${numberedItem.nodeType}-${i}`}>
                              {numberedItem.content.map(content =>
                                  content.nodeType === `${BLOCKS.PARAGRAPH}` ? (
                                      <HStack
                                          alignItems="center"
                                          key={`next-steps-child-${i}`}
                                      >
                                          <Circle bg="#111111" size="28px">
                                              <Text
                                                  color="white"
                                                  fontSize="xs"
                                                  fontWeight="700"
                                                  mt={0.5}
                                              >
                                                  {i + 1}
                                              </Text>
                                          </Circle>
                                          {documentToReactComponents(
                                              content as any,
                                              headerRenderer
                                          )}
                                      </HStack>
                                  ) : (
                                      <Box key={`list-${i}`}>
                                          {documentToReactComponents(
                                              content as any,
                                              listRenderer
                                          )}
                                      </Box>
                                  )
                              )}
                          </Stack>
                      ) : (
                          <Box key={`list-${i}`}>
                              {documentToReactComponents(
                                  numberedItem as any,
                                  listRenderer
                              )}
                          </Box>
                      )
                  )
                : documentToReactComponents(data as any, listRenderer)}
        </Stack>
    );
};

export default BreachAssetTypeNextSteps;
