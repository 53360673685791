import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import HeaderMenuProfileSvg from '../../assets/HeaderMenuProfileSvg';
import SignOutSvg from '../../assets/SignOutSvg';
import { AppFeatures, AppRoutes } from '../../config/config';
import { getComponentStyles } from '../../config/theme';
import { account } from '../../entities/session';
import useContent from '../../hooks/useContent';
import { isFeatureEnabled } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';

const DesktopMenu = ({ onSignOut }) => {
    const content = useContent();
    const accountInfo = account.use();
    const { desktop } = getComponentStyles('HeaderMenu');

    return (
        <Box id={'header-menu-desktop'} {...desktop.root}>
            <Flex direction={'row'} p={0} m={0} gap={3} float={'right'}>
                <Box id={'headermenu-profile'} backgroundColor={'bgHeader'} p={0} m={0}>
                    {isFeatureEnabled(AppFeatures.PROFILE) ? (
                        <RouteLink to={AppRoutes.PROFILE}>
                            <Flex direction={'row'} gap={2}>
                                <HeaderMenuProfileSvg />
                                <Text fontSize={'16px'} color={'white'} mt={'5px'}>
                                    {accountInfo?.firstName}
                                </Text>
                            </Flex>
                        </RouteLink>
                    ) : (
                        <Flex direction={'row'} gap={2}>
                            <HeaderMenuProfileSvg />
                            <Text fontSize={'16px'} color={'white'} mt={'5px'}>
                                {accountInfo?.firstName}
                            </Text>
                        </Flex>
                    )}
                </Box>
                <Divider orientation="vertical" color={'white'} h={'28px'} />
                <Box id={'headermenu-signout'} cursor={'pointer'}>
                    <Flex direction={'row'} gap={2}>
                        <SignOutSvg />
                        <Button
                            variant={'link'}
                            textDecoration="none"
                            onClick={onSignOut}
                        >
                            {content('header.signout')}
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default DesktopMenu;
