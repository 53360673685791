/* eslint-disable array-callback-return */
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route } from 'react-router-dom';
import { AppConfig, AppFeatures, AppRoutes } from '../config/config';

import appTheme from '../config/theme';
import LoginPage from './LoginPage';
import LoginOtpPage from './LoginOtpPage';

import '../assets/fonts/fonts.css';
import DashboardPage from './DashboardPage';
import AssessmentPage from './AssessmentPage';
import BreachPage from './BreachPage';
import AssessmentResultPage from './AssessmentResultPage';
import { AssessmentGuidePage } from './AssessmentGuidePage';
import BreachDetailsPage from './BreachDetailsPage';
import DiscoverSecurityPage from './DiscoverSecurityPage';
import ProtectedPage from '../components/layouts/ProtectedPage';
import DiscoverArticlePage from './DiscoverArticlePage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ProfilePage from './ProfilePage';
import { isFeatureEnabled } from '../utils/helpers';
import Shell from '../components/layouts/Shell';
import SimpleRegistrationPage from './SimpleRegistrationPage';
import RegistrationPage from './RegistrationPage';
import RegistrationOtpPage from './RegistrationOtpPage';
import VerifyMobilePage from './VerifyMobilePage';
import VerifyMobileOtpPage from './VerifyMobileOtpPage';

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: 0,
            },
        },
    });

    document.title = AppConfig.name || '';

    let publicRoutes = [
        { path: '/', enabled: true, element: <LoginPage /> },
        { path: AppRoutes.INDEX, enabled: true, element: <LoginPage /> },
        { path: AppRoutes.LOGIN, enabled: true, element: <LoginPage /> },
        { path: AppRoutes.LOGIN_REDIRECT, enabled: true, element: <LoginPage /> },
        { path: AppRoutes.OTP, enabled: true, element: <LoginOtpPage /> },
        {
            path: AppRoutes.REGISTRATION_OTP,
            enabled: isFeatureEnabled(AppFeatures.REGISTRATION),
            element: <RegistrationOtpPage />,
        },
        {
            path: AppRoutes.REGISTRATION,
            enabled: isFeatureEnabled(AppFeatures.REGISTRATION),
            element: <RegistrationPage />,
        },
        {
            path: AppRoutes.REGISTRATION_MDN,
            enabled: isFeatureEnabled(AppFeatures.REGISTRATION),
            element: <RegistrationPage />,
        },
        {
            path: AppRoutes.SIMPLE_REGISTRATION,
            element: <SimpleRegistrationPage />,
            enabled: isFeatureEnabled(AppFeatures.SIMPLE_REGISTRATION),
        },
        {
            path: AppRoutes.VERIFY_MOBILE,
            element: <VerifyMobilePage />,
            enabled: isFeatureEnabled(AppFeatures.REGISTRATION),
        },
        {
            path: AppRoutes.VERIFY_MOBILE_OTP,
            element: <VerifyMobileOtpPage />,
            enabled: isFeatureEnabled(AppFeatures.REGISTRATION),
        },
    ];

    let protectedRoutes = [
        { path: AppRoutes.DASHBOARD, enabled: true, element: <DashboardPage /> },
        {
            path: AppRoutes.DASHBOARD_COMPONENT,
            enabled: true,
            element: <DashboardPage />,
        },
        { path: AppRoutes.BREACH, enabled: true, element: <BreachPage /> },
        { path: AppRoutes.BREACH_DETAILS, enabled: true, element: <BreachDetailsPage /> },
        { path: AppRoutes.BREACH_VERIFY_EMAIL, enabled: true, element: <BreachPage /> },
        { path: AppRoutes.ASSESSMENT, enabled: true, element: <AssessmentPage /> },
        { path: AppRoutes.ASSESSMENT_RETAKE, enabled: true, element: <AssessmentPage /> },
        {
            path: AppRoutes.ASSESSMENT_RESULT,
            enabled: true,
            element: <AssessmentResultPage />,
        },
        {
            path: AppRoutes.ASSESSMENT_GUIDE,
            enabled: true,
            element: <AssessmentGuidePage />,
        },
        { path: AppRoutes.DISCOVER, enabled: true, element: <DiscoverSecurityPage /> },
        {
            path: AppRoutes.DISCOVER_ARTICLE,
            enabled: true,
            element: <DiscoverArticlePage />,
        },
        {
            path: AppRoutes.PROFILE,
            element: <ProfilePage />,
            enabled: isFeatureEnabled(AppFeatures.PROFILE),
        },
    ];

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={appTheme}>
                <Shell>
                    <Routes>
                        {publicRoutes.map((route, i) => {
                            const { path, enabled, element } = route;

                            if (enabled) {
                                return (
                                    <Route
                                        path={path}
                                        element={element}
                                        key={`public-route-${i}`}
                                    />
                                );
                            }
                        })}

                        <Route element={<ProtectedPage />}>
                            {protectedRoutes.map((route, i) => {
                                const { path, enabled, element } = route;

                                if (enabled) {
                                    return (
                                        <Route
                                            key={`protected-route-${i}`}
                                            path={path}
                                            element={element}
                                        />
                                    );
                                }
                            })}
                        </Route>
                    </Routes>
                </Shell>
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default App;
