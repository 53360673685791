import { AddIcon } from '@chakra-ui/icons';
import { Box, HStack, Tab, TabList, Tabs, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { AppFeatures } from '../../config/config';
import { IAccountEmail } from '../../config/interface';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import { isFeatureEnabled } from '../../utils/helpers';
import CountBadge from '../common/CountBadge';

const BreachTabs = ({
    selectedEmail,
    emails,
    isLoading,
    onTabChange = () => {},
    onAddEmail = () => {},
}: {
    selectedEmail: IAccountEmail;
    emails: IAccountEmail[];
    isLoading: boolean;
    onTabChange?: Function;
    onAddEmail?: Function;
}) => {
    const content = useContent();
    const { tabs } = getPageStyles('BreachPage');

    return (
        <Box
            id={'breachpage-email-tab'}
            {...tabs.root}
            display={{ base: 'none', md: 'block' }}
        >
            <Tabs variant={'enclosed'} mt={'-25px'}>
                <TabList>
                    {emails &&
                        emails.map((item, index) => {
                            const { email, lastScanDate, exposed, resolved, tag } = item;
                            return (
                                <Tab
                                    key={`breach-tablist-${index}`}
                                    {...tabs.tabItem}
                                    isDisabled={isLoading}
                                    opacity={selectedEmail.email === email ? 1 : 0.5}
                                    onClick={() => {
                                        if (!isLoading) {
                                            onTabChange({
                                                email,
                                                lastScanDate,
                                                exposed,
                                                resolved,
                                            });
                                        }
                                    }}
                                >
                                    <HStack>
                                        <VStack alignItems={'baseline'}>
                                            <Text
                                                {...tabs.tabItemLabel}
                                                color={
                                                    selectedEmail.email === email
                                                        ? 'brand.primary.500'
                                                        : 'grayscale.200'
                                                }
                                            >
                                                {tag}
                                            </Text>
                                            <Text {...tabs.tabItemText}>{email}</Text>
                                        </VStack>
                                        {exposed && exposed > 0 && (
                                            <CountBadge count={exposed || 0} />
                                        )}
                                    </HStack>
                                </Tab>
                            );
                        })}
                    {isFeatureEnabled(AppFeatures.ADD_SECONDARY_EMAIL) && (
                        <Tab
                            {...tabs.tabItemAdd}
                            onClick={onAddEmail}
                            isDisabled={isLoading}
                        >
                            <HStack alignItems={'center'}>
                                <AddIcon color={'white'} />
                                <Text {...tabs.tabItemAddText}>
                                    {content('breach.addemail')}
                                </Text>
                            </HStack>
                        </Tab>
                    )}
                </TabList>
            </Tabs>
        </Box>
    );
};

export default BreachTabs;
