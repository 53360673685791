import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    cardAnatomy.keys
);

const baseStyle = definePartsStyle({
    container: {
        borderRadius: 8,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden',
    },
    header: {
        px: 4,
        py: 6,
    },
    body: {
        px: 4,
    },
});

export const theme = defineMultiStyleConfig({ baseStyle });
