import * as React from 'react';

const NoAssessmentScoreSvg = props => (
    <svg
        width={props.w || 120}
        height={props.h || 120}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M120 60c0 33.137-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0s60 26.863 60 60ZM7.2 60c0 29.16 23.64 52.8 52.8 52.8 29.16 0 52.8-23.64 52.8-52.8 0-29.16-23.64-52.8-52.8-52.8C30.84 7.2 7.2 30.84 7.2 60Z"
            fill="#A5AAAF"
        />
        <path
            d="M60.549 63.776c-1.952 0-3.552-.56-4.8-1.68-1.216-1.152-1.824-2.64-1.824-4.464 0-1.536.448-2.8 1.344-3.792.928-.992 2.208-1.808 3.84-2.448 1.856-.704 3.12-1.344 3.792-1.92.672-.576 1.008-1.264 1.008-2.064s-.288-1.392-.864-1.776c-.576-.416-1.328-.624-2.256-.624-.992 0-1.84.288-2.544.864-.704.544-1.216 1.328-1.536 2.352l-5.232-.864c.384-2.112 1.376-3.856 2.976-5.232 1.632-1.408 3.696-2.112 6.192-2.112 2.688 0 4.832.688 6.432 2.064 1.632 1.376 2.448 3.216 2.448 5.52 0 1.792-.528 3.184-1.584 4.176-1.024.992-2.704 1.936-5.04 2.832-1.6.64-2.688 1.168-3.264 1.584-.544.416-.816.944-.816 1.584 0 .512.16.928.48 1.248a1.91 1.91 0 0 0 1.248.432c1.312 0 2.224-.736 2.736-2.208l4.224 1.584c-.544 1.536-1.424 2.752-2.64 3.648-1.184.864-2.624 1.296-4.32 1.296Zm0 10.512c-1.12 0-2.064-.384-2.832-1.152-.768-.768-1.152-1.696-1.152-2.784 0-1.12.384-2.064 1.152-2.832.8-.8 1.744-1.2 2.832-1.2 1.088 0 2.016.4 2.784 1.2.8.768 1.2 1.712 1.2 2.832 0 1.088-.384 2.016-1.152 2.784-.768.768-1.712 1.152-2.832 1.152Z"
            fill="#111"
        />
    </svg>
);

export default NoAssessmentScoreSvg;
