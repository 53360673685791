import * as React from 'react';

const AssetEmailSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="m8.9 14.514 15.3 13.714 15.3-13.714" stroke="#111" />
        <path
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.2 12.8h34v24h-34z"
        />
    </svg>
);

export default AssetEmailSvg;
