import * as React from 'react';

const AssetAddressesSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M7.6 11.556 18.267 8v28.444L7.6 40V11.556ZM28.933 11.556 39.6 8v28.444L28.933 40V11.556ZM28.933 11.556 18.267 8v28.444L28.933 40V11.556Z"
            stroke="#111"
            strokeLinejoin="round"
        />
    </svg>
);

export default AssetAddressesSvg;
