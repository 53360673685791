import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    formAnatomy.keys
);

const baseStyle = definePartsStyle({
    container: {
        label: {
            fontWeight: 'bold',
            fontSize: 14,
            lineHeight: 17 / 14,
        },
    },
    helperText: {
        fontSize: 12,
        color: 'grayscale.200',
    },
});

export const theme = defineMultiStyleConfig({ baseStyle });
