import * as React from 'react';

const MobileDashboardSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20.3 4v-.3H3.7V7.3h16.6V4ZM4 8.7h-.3V20.3H7.3V8.7H4ZM8.7 20v.3H20.3V8.7H8.7V20ZM3 2.3h18a.7.7 0 0 1 .7.7v18a.7.7 0 0 1-.7.7H3a.7.7 0 0 1-.7-.7V3a.7.7 0 0 1 .7-.7Z"
            stroke="#fff"
            strokeWidth={0.6}
        />
    </svg>
);

export default MobileDashboardSvg;
