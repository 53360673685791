import React from 'react';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../config/config';
import SidebarNavItem from './SidebarNavItem';
import { getComponentStyles } from '../../config/theme';
import { exposedCount as exposedCountEntity } from '../../entities/assets';
import RouteLink from '../common/RouteLink';

import imgSidebarDashboard from '../../assets/images/sidebar-dashboard.png';
import imgSidebarMonitoring from '../../assets/images/sidebar-monitoring.png';
import imgSidebarAssessment from '../../assets/images/sidebar-assessment.png';
import imgSidebarDiscovery from '../../assets/images/sidebar-discovery.png';
import useContent from '../../hooks/useContent';

const SidebarNav = () => {
    const content = useContent();
    const location = useLocation();
    const exposedTotal = exposedCountEntity.use();
    const loc = location?.pathname || '/dashboard';

    const { main, exposedCount } = getComponentStyles('SidebarNav');

    return (
        <Box id={'sidebarnav'} {...main}>
            <Flex direction={'column'}>
                <SidebarNavItem
                    item={'dashboard'}
                    isActive={loc === AppRoutes.DASHBOARD ? true : false}
                >
                    <>
                        <RouteLink to={AppRoutes.DASHBOARD} textDecoration={'none'}>
                            <VStack>
                                <Image
                                    src={imgSidebarDashboard}
                                    alt={'sidebar nav dashboard'}
                                    w={'40px'}
                                    h={'40px'}
                                />
                                <Text>{content('sidebar.dashboard')}</Text>
                            </VStack>
                        </RouteLink>
                    </>
                </SidebarNavItem>
                <SidebarNavItem
                    item={'monitoring'}
                    isActive={loc.indexOf(AppRoutes.BREACH) > -1 ? true : false}
                >
                    <Box position={'relative'}>
                        <RouteLink to={AppRoutes.BREACH} textDecoration={'none'}>
                            <VStack>
                                <Image
                                    src={imgSidebarMonitoring}
                                    alt={'sidebar nav monitoring'}
                                    w={'48px'}
                                    h={'48px'}
                                />
                                <Text>{content('sidebar.monitoring')}</Text>
                            </VStack>
                        </RouteLink>
                        {exposedTotal > 0 && <Box {...exposedCount}>{exposedTotal}</Box>}
                    </Box>
                </SidebarNavItem>
                <SidebarNavItem
                    item={'assessment'}
                    isActive={loc.indexOf(AppRoutes.ASSESSMENT) > -1 ? true : false}
                >
                    <>
                        <RouteLink to={AppRoutes.ASSESSMENT} textDecoration={'none'}>
                            <VStack>
                                <Image
                                    src={imgSidebarAssessment}
                                    alt={'sidebar nav assessment'}
                                    w={'48px'}
                                    h={'48px'}
                                />
                                <Text>{content('sidebar.assessment')}</Text>
                            </VStack>
                        </RouteLink>
                    </>
                </SidebarNavItem>
                <SidebarNavItem
                    item={'discover'}
                    isActive={loc.indexOf(AppRoutes.DISCOVER) > -1 ? true : false}
                >
                    <>
                        <RouteLink to={AppRoutes.DISCOVER} textDecoration={'none'}>
                            <VStack>
                                <Image
                                    src={imgSidebarDiscovery}
                                    alt={'sidebar nav discovery'}
                                    w={'48px'}
                                    h={'48px'}
                                />
                                <Text>{content('sidebar.discover')}</Text>
                            </VStack>
                        </RouteLink>
                    </>
                </SidebarNavItem>
            </Flex>
        </Box>
    );
};

export default SidebarNav;
