import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SadIcon: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
        <path
            d="M29.7392 16.2597C29.7392 23.9676 23.6113 29.6566 16.0003 29.6566C8.38935 29.6566 2.26147 23.9676 2.26147 16.2597C2.26147 8.51889 8.42264 2.26211 16.0003 2.26211C23.578 2.26211 29.7392 8.51889 29.7392 16.2597Z"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.1295 21.2341C21.0615 19.4389 18.5576 17.7246 15.9287 17.7246C13.2997 17.7246 11.3342 18.9304 9.8685 21.2341"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.7939 12.0093V11.2639V11.2639C19.7939 11.1868 19.8964 11.1761 19.9114 11.2517C19.9931 11.6659 20.0516 11.9717 20.2455 12.4419C20.2917 13.3327 20.1695 13.6959 19.9679 13.6959C19.8718 13.6959 19.7939 13.4111 19.7939 13.0598V12.3073"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.2178 12.0093V11.2639V11.2639C12.2178 11.1868 12.1153 11.1761 12.1004 11.2517C12.0187 11.6659 11.9602 11.9717 11.7663 12.4419C11.7201 13.3327 11.8423 13.6959 12.0438 13.6959C12.1399 13.6959 12.2178 13.4111 12.2178 13.0598V12.3073"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);

export default SadIcon;
