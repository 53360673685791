import * as React from 'react';

const CalendarSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)" stroke="#111">
            <path d="M3 5h18v16H3V5Z" strokeLinejoin="round" />
            <path d="M21 9H3M7 5V3M17 5V3" strokeLinecap="round" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default CalendarSvg;
