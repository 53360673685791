import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const WizardCard: React.FC<FlexProps> = ({ children, ...props }) => {
    return (
        <Flex flexDir="column" maxW="464px" pt={4} pb={24} {...props}>
            {children}
        </Flex>
    );
};
