import * as React from 'react';

const ShieldGreenSvg = props => (
    <svg width={14} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 3.255v4.87c-.005 3.234 1.86 6.14 4.696 7.313l.64.264c.427.175.9.175 1.326 0l.64-.264c2.836-1.174 4.701-4.079 4.697-7.313v-4.87a.986.986 0 0 0-.535-.896A12.613 12.613 0 0 0 7 1.167 12.613 12.613 0 0 0 1.534 2.36.986.986 0 0 0 1 3.255Z"
            fill="#008662"
            stroke="#008662"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m10.299 5.312-3.62 5.568-2.747-2.92"
            stroke="#fff"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ShieldGreenSvg;
