import { Button, ButtonProps, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { AssessmentQuestion } from '../../../config/interface';
import {
    WizardCardBottom,
    WizardCardStepperActions,
} from '../../common/Wizard/WizardCardBottom';
import {
    WizardCardProgress,
    WizardCardStep,
    WizardCardTop,
    WizardCardTopHeading,
} from '../../common/Wizard/WizardCardTop';
import { WizardCard } from '../../common/Wizard/WizardCard';

interface Props {
    data: AssessmentQuestion;
    prevButtonProps: ButtonProps;
    nextButtonProps: ButtonProps;
    onOptionClick: (value: string) => void;
    step: number;
    numSteps: number;
    value: string;
}

const QuestionWizardCard: React.FC<Partial<Props>> = ({
    data,
    prevButtonProps,
    nextButtonProps,
    onOptionClick,
    value,
    step = 0,
    numSteps = 0,
}) => {
    const { question, options } = data || {};
    const progress = (step / numSteps) * 100;

    return (
        <WizardCard mb={!value ? '56.5px' : 0}>
            <WizardCardTop>
                <Flex align="center" justify="center" textAlign="center" minH="180px">
                    <WizardCardTopHeading>{question}</WizardCardTopHeading>
                </Flex>
                {step && (
                    <WizardCardStep fontWeight="500" mt={0}>
                        <b>Q{step}</b> of {numSteps}
                    </WizardCardStep>
                )}
                <WizardCardProgress value={progress} />
            </WizardCardTop>
            <WizardCardBottom>
                <Stack spacing={4}>
                    {options?.map(option => (
                        <Button
                            variant={value === option ? 'solid' : 'outline'}
                            key={`Option-${option}`}
                            onClick={() => {
                                if (onOptionClick) {
                                    onOptionClick(option);
                                }
                            }}
                            color={value === option ? 'white' : 'black'}
                        >
                            {option}
                        </Button>
                    ))}
                </Stack>

                {value && (
                    <WizardCardStepperActions
                        prevButtonProps={{
                            variant: 'ghost',
                            ...prevButtonProps,
                        }}
                        nextButtonProps={{
                            variant: 'ghost',
                            textDecor: 'underline',
                            ...nextButtonProps,
                        }}
                    />
                )}
            </WizardCardBottom>
        </WizardCard>
    );
};

export default QuestionWizardCard;
