import * as React from 'react';

const MobileMonitoringSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2.25 12a9.75 9.75 0 1 0 19.5 0 9.75 9.75 0 0 0-19.5 0Z"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.27 12.848a6.788 6.788 0 0 1 10.12-6.724M18.134 14.898a6.784 6.784 0 0 1-11.328 1.464M17.874 8.609c.453.782.744 1.646.856 2.543"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.88 15.173a3.815 3.815 0 0 1 3.391-6.77M15.72 11.152a3.815 3.815 0 0 1-2.024 4.266M12 12l5.92-5.921M11.788 15.391a.212.212 0 0 1 .212.212M11.575 15.603a.212.212 0 0 1 .212-.212M11.787 15.815a.212.212 0 0 1-.212-.212M12 15.603a.212.212 0 0 1-.212.212M18.57 12.848a.212.212 0 0 1 .212.212M18.358 13.06a.212.212 0 0 1 .212-.212M18.57 13.272a.212.212 0 0 1-.212-.212M18.782 13.06a.212.212 0 0 1-.212.212M16.372 18.783a.212.212 0 0 1 .212.212M16.16 18.995a.212.212 0 0 1 .211-.212M16.371 19.207a.212.212 0 0 1-.212-.212M16.584 18.995a.212.212 0 0 1-.212.212M5.853 14.543a.212.212 0 0 1 .212.212M5.64 14.755a.212.212 0 0 1 .213-.212M5.853 14.967a.212.212 0 0 1-.212-.212M6.065 14.755a.212.212 0 0 1-.212.212M6.197 6.065a.212.212 0 0 1 .212.212M5.985 6.277a.212.212 0 0 1 .212-.212M6.197 6.49a.212.212 0 0 1-.212-.213M6.41 6.277a.212.212 0 0 1-.213.212"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default MobileMonitoringSvg;
