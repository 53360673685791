import {
    Badge,
    Box,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Spinner,
    Td,
    Text,
    Tr,
    VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import DeleteRecordIcon from '../../assets/DeleteRecordIcon';
import { getPageStyles } from '../../config/theme';
import useLogout from '../../hooks/useLogout';
import { updateProfile } from '../../services/apiService';
import { getResponseError, lastScanFormat } from '../../utils/helpers';
import OverviewCard from '../common/OverviewCard';
import StateSwitch from '../common/StateSwitch';

const ProfileEmailDisplay = ({
    isDesktop = true,
    tag,
    email,
    isPrimary,
    firstName,
    lastName,
    createdDate,
    lastScanDate,
    isChecked,
    monthlyScanSubscription,
    onToggleMonthlyReportSuccess,
    onToggleConfirmEmailDelete,
    isDeletingSecondaryEmail,
}) => {
    const doLogout = useLogout();
    const { emails, card } = getPageStyles('ProfilePage');

    const { mutate: toggleMonthlyScan, isLoading: isTogglingMonthlyReport } = useMutation(
        {
            mutationFn: updateProfile,
            onSuccess: data => {
                onToggleMonthlyReportSuccess(email, !monthlyScanSubscription);
                monthlyScanSubscription = !monthlyScanSubscription;
            },
            onError: error => {
                const { status } = getResponseError(error);
                if (status === 401) {
                    doLogout();
                }
            },
        }
    );

    const handleToggleChange = useCallback(
        (email, checked) => {
            toggleMonthlyScan({
                email: email,
                fields: {
                    monthlyScanSubscription: checked,
                },
            });
        },
        [toggleMonthlyScan]
    );

    const handleDeleteEmail = useCallback(() => {
        onToggleConfirmEmailDelete(email);
    }, [onToggleConfirmEmailDelete, email]);

    return (
        <>
            {isDesktop === true && (
                <Tr borderBottom={'1px dashed #000'}>
                    <Td>
                        {isPrimary && (
                            <Badge variant={'profileTag'}>{tag || 'Primary Email'}</Badge>
                        )}
                        {!isPrimary && (
                            <Badge variant={'profileTag'}>
                                {tag || 'Secondary Email'}
                            </Badge>
                        )}
                    </Td>
                    <Td>
                        <VStack alignItems={'baseline'}>
                            <Text {...emails.name}>{`${firstName} ${lastName}`}</Text>
                            <Text {...emails.subfield} {...emails.field}>
                                {email}
                            </Text>
                        </VStack>
                    </Td>
                    <Td>
                        {createdDate && (
                            <Text {...emails.field}>
                                {createdDate ? lastScanFormat(createdDate) : '--'}
                            </Text>
                        )}
                    </Td>
                    <Td>
                        {lastScanDate && (
                            <Text {...emails.field}>
                                {lastScanDate ? lastScanFormat(lastScanDate) : '--'}
                            </Text>
                        )}
                    </Td>
                    <Td>
                        {isTogglingMonthlyReport ? (
                            <Spinner variant={'componentLoader'} />
                        ) : (
                            <StateSwitch
                                isChecked={isChecked}
                                disabled={isTogglingMonthlyReport}
                                onChange={v => {
                                    handleToggleChange(email, v.target.checked);
                                }}
                            />
                        )}
                    </Td>
                    <Td>
                        {!isPrimary && (
                            <IconButton
                                aria-label={'icon to delete and remove icon'}
                                backgroundColor={'transparent'}
                                icon={<DeleteRecordIcon />}
                                isLoading={isDeletingSecondaryEmail}
                                isDisabled={isDeletingSecondaryEmail}
                                w={'18px'}
                                h={'19px'}
                                _hover={{
                                    backgroundColor: 'transparent',
                                }}
                                onClick={handleDeleteEmail}
                            />
                        )}
                    </Td>
                </Tr>
            )}
            {isDesktop === false && (
                <OverviewCard
                    id={'emaillists-mobile'}
                    title={
                        <Box color={isPrimary ? 'white' : '#111111'}>
                            {tag || (isPrimary ? 'Primary Email' : 'Secondary Email')}
                        </Box>
                    }
                    customProps={card.mobile.root}
                    customHeaderProps={
                        isPrimary
                            ? card.mobile.headerPrimary
                            : card.mobile.headerSecondary
                    }
                >
                    <Flex direction={'column'}>
                        <HStack>
                            <VStack alignItems={'baseline'}>
                                <Text
                                    {...emails.title}
                                >{`${firstName} ${lastName}`}</Text>
                                <Text {...emails.field} {...emails.subfield}>
                                    {email}
                                </Text>
                            </VStack>
                            <Spacer />
                            {!isPrimary && (
                                <IconButton
                                    aria-label={'icon to delete and remove icon'}
                                    backgroundColor={'transparent'}
                                    icon={<DeleteRecordIcon />}
                                    isLoading={isDeletingSecondaryEmail}
                                    isDisabled={isDeletingSecondaryEmail}
                                    w={'18px'}
                                    h={'19px'}
                                    _hover={{
                                        backgroundColor: 'transparent',
                                    }}
                                    onClick={handleDeleteEmail}
                                />
                            )}
                        </HStack>

                        <HStack mt={'16px'}>
                            <VStack alignItems={'baseline'}>
                                <Text {...card.mobile.field} fontWeight={700}>
                                    Date Added
                                </Text>
                                <Text {...card.mobile.value}>
                                    {createdDate ? lastScanFormat(createdDate) : '--'}
                                </Text>
                            </VStack>
                            <Box w={'60px'}>&nbsp;</Box>
                            <VStack alignItems={'baseline'}>
                                <Text {...card.mobile.field} fontWeight={700}>
                                    Last Scanned
                                </Text>
                                <Text {...card.mobile.value}>
                                    {lastScanDate ? lastScanFormat(lastScanDate) : '--'}
                                </Text>
                            </VStack>
                        </HStack>
                        <Box mt={'16px'}>
                            <Text {...card.mobile.field} fontWeight={700}>
                                Monthly Report
                            </Text>

                            {isTogglingMonthlyReport ? (
                                <Spinner variant={'componentLoader'} />
                            ) : (
                                <StateSwitch
                                    isChecked={isChecked}
                                    disabled={isTogglingMonthlyReport}
                                    onChange={v => {
                                        handleToggleChange(email, v.target.checked);
                                    }}
                                />
                            )}
                        </Box>
                    </Flex>
                </OverviewCard>
            )}
        </>
    );
};

export default ProfileEmailDisplay;
