import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
    onScroll?: Function;
    children?: ReactNode;
    styleProps?: any;
}

const BorderedBox: React.FC<Props> = ({ children, styleProps, onScroll }) => {
    return (
        <Box
            borderColor={'grayscale.550'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderRadius={'10px'}
            p={'10px'}
            minWidth={'420px'}
            width={'auto'}
            marginBottom={'15px'}
            backgroundColor="white"
            {...styleProps}
            onScroll={e => {
                const target = e.currentTarget;
                if (onScroll) {
                    onScroll(target);
                }
            }}
        >
            {children}
        </Box>
    );
};

export default BorderedBox;
