import * as React from 'react';

const AssetPhoneSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m27.898 32.543 4.256-4.256a1 1 0 0 1 1.288-.108l6.794 4.842a1 1 0 0 1 .127 1.521l-1.63 1.63c-.732.731-1.788 1.033-2.772.718-3.01-.961-9.724-3.58-15.303-9.16-5.579-5.58-8.198-12.292-9.16-15.302-.314-.985-.013-2.041.718-2.772l1.63-1.63a1 1 0 0 1 1.522.126l4.84 6.794a1 1 0 0 1-.106 1.288l-4.257 4.256"
            stroke="#111"
            strokeLinecap="round"
        />
    </svg>
);

export default AssetPhoneSvg;
