import * as React from 'react';

const AssetFullnamesSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8 40.2c0-6 8-6 12-10 2-2-4-2-4-12 0-6.666 2.666-10 8-10s8 3.334 8 10c0 10-6 10-4 12 4 4 12 4 12 10"
            stroke="#111"
            strokeLinecap="round"
        />
    </svg>
);

export default AssetFullnamesSvg;
