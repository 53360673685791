import {
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    Heading,
    Image,
    ImageProps,
} from '@chakra-ui/react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

export const orderedLisNodeOptions: Options['renderNode'] = {
    [BLOCKS.OL_LIST]: (node, children) => {
        return (
            <OrderedList my={3} spacing={2} pl={3}>
                {children}
            </OrderedList>
        );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
};

export const unorderedListNodeOptions: Options['renderNode'] = {
    [BLOCKS.UL_LIST]: (node, children) => {
        return (
            <UnorderedList my={3} spacing={2} pl={3}>
                {children}
            </UnorderedList>
        );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
};

export const articleHeadingNodeOptions: Options['renderNode'] = {
    [BLOCKS.HEADING_1]: (node, children) => (
        <Heading as="h1" size="3xl">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
        <Heading as="h2" size="xl">
            {children}
        </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
        <Heading as="h3" size="lg">
            {children}
        </Heading>
    ),
};

export const imageAssetNodeOptions = (props: ImageProps = {}): Options['renderNode'] => ({
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <Image
            src={node.data.target.fields.file.url}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
            {...props}
        />
    ),
});

export const defaultRenderNodeOptions: Options['renderNode'] = {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text whiteSpace="pre-wrap">{children}</Text>,
    [MARKS.BOLD]: (node, children) => <b>{children}</b>,
    ...orderedLisNodeOptions,
    ...unorderedListNodeOptions,
    ...imageAssetNodeOptions(),
};
