import { Icon, IconProps } from '@chakra-ui/react';

const ProfilePageIcon: React.FC<IconProps> = props => (
    <Icon
        width={50}
        height={50}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            clipRule="evenodd"
            d="M25 49c13.255 0 24-10.745 24-24S38.255 1 25 1 1 11.745 1 25s10.745 24 24 24Z"
            stroke="#111"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M25 27.087a8.87 8.87 0 1 0 0-17.74 8.87 8.87 0 0 0 0 17.74Z"
            stroke="#111"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M38.183 38.567a14.584 14.584 0 0 0-26.366 0"
            stroke="#111"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);

export default ProfilePageIcon;
