import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronLeftBlackSvg from '../../assets/ChevronLeftBlackSvg';
import ChevronRightBlackSvg from '../../assets/ChevronRightBlackSvg';
import { AppRoutes } from '../../config/config';
import { IBreadcrumbItem } from '../../config/interface';
import RouteLink from './RouteLink';

const Breadcrumbs = ({
    items,
    separator = <ChevronRightBlackSvg />,
    currentOnly = false,
}: {
    items: IBreadcrumbItem[];
    separator?: ReactElement;
    currentOnly?: boolean;
}) => {
    const lastItem = items[items.length - 1];
    const navigate = useNavigate();

    return (
        <>
            {!currentOnly && (
                <Breadcrumb
                    id={'breadcrumb'}
                    spacing={{ base: '3px', md: '8px' }}
                    fontSize={{ base: '14px', md: '14px' }}
                    separator={separator}
                >
                    {items.map((item, i) => {
                        const { label, href, current, content } = item;

                        return (
                            <BreadcrumbItem key={`breadcrumb-${i}`}>
                                <BreadcrumbLink
                                    onClick={e => {
                                        e.preventDefault();
                                        navigate(href);
                                    }}
                                    isCurrentPage={current || false}
                                    fontWeight={700}
                                    color={!current ? 'grayscale.200' : 'inherit'}
                                >
                                    {content ? content : label}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        );
                    })}
                </Breadcrumb>
            )}
            {currentOnly && (
                <Flex direction={'row'} id={'breadcrumb-single'}>
                    <ChevronLeftBlackSvg />
                    <RouteLink to={lastItem?.href || AppRoutes.BREACH} fontWeight={700}>
                        {lastItem.content || lastItem.label}
                    </RouteLink>
                </Flex>
            )}
        </>
    );
};

export default Breadcrumbs;
