import { Box, BoxProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { ChartDonut, ChartLabel } from '@patternfly/react-charts';
import React from 'react';
import { RiskLevel } from '../../config/config';

interface Props extends BoxProps {
    risk?: RiskLevel;
    score?: number;
}

// TODO: Fix UI
const RiskScoreDonutChart: React.FC<Props> = ({ risk = 'LOW', score = 0, ...props }) => {
    // TODO: setup theme typing
    const theme: any = useTheme();

    return (
        <Box {...props}>
            <ChartDonut
                ariaDesc="Risk Score"
                ariaTitle="Risk Score"
                constrainToVisibleArea
                data={[
                    { x: 'Score', y: score },
                    { x: '', y: 100 - score },
                ]}
                labels={({ datum }) => `${datum.x}: ${datum.y}%`}
                name="chart1"
                subTitle={`/100`}
                title={`${score}`}
                colorScale={[
                    `var(--chakra-colors-${risk.toLowerCase()}Risk-500)`,
                    '#E6E6EB',
                ]}
                padding={0}
                innerRadius={75}
                titleComponent={
                    <ChartLabel
                        y={107}
                        style={theme?.components?.Widget?.Security?.donut?.title}
                    />
                }
                subTitleComponent={
                    <ChartLabel
                        y={140}
                        style={{
                            ...(theme?.components?.Widget?.Security?.donut?.subtitle ||
                                {}),
                            fontSize: '16px',
                        }}
                    />
                }
            />
        </Box>
    );
};

export default RiskScoreDonutChart;
