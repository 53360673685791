import * as React from 'react';

const ArrowGoToLinkSvg = props => (
    <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m14.11 8-3.805 3.805-.943-.943L12.224 8 9.362 5.138l.943-.943L14.11 8Z"
            fill="#111"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.833 7.333h9.334v1.334H3.834V7.333Z"
            fill="#111"
        />
    </svg>
);

export default ArrowGoToLinkSvg;
