import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Link } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MobileAssessmentSvg from '../../assets/MobileAssessmentSvg';
import MobileDashboardSvg from '../../assets/MobileDashboardSvg';
import MobileDiscoverySvg from '../../assets/MobileDiscoverySvg';
import MobileMonitoringSvg from '../../assets/MobileMonitoringSvg';
import MobileProfileSvg from '../../assets/MobileProfileSvg';
import MobileSignoutSvg from '../../assets/MobileSignoutSvg';
import { AppFeatures, AppRoutes } from '../../config/config';
import { getComponentStyles } from '../../config/theme';
import { account } from '../../entities/session';
import useContent from '../../hooks/useContent';
import { isFeatureEnabled } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';

const MobileMenu = ({ onSignOut }) => {
    const content = useContent();
    const { mobile } = getComponentStyles('HeaderMenu');
    const [showMenu, setShowMenu] = useState(false);

    const accountInfo = account.use();

    const location = useLocation();
    const loc = location?.pathname || '/dashboard';

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const LinkItem = ({
        as = 'menu',
        href = '',
        icon,
        text,
        active = false,
        onClick = () => {},
    }) => {
        return (
            <Box p={'20px'} boxShadow={active ? `inset 4px 0px 0px #8223D2` : 'unset'}>
                {as === 'menu' && (
                    <RouteLink to={href} {...mobile.linkItem}>
                        <Flex direction={'row'}>
                            <Box {...mobile.linkIcon}>{icon}</Box>
                            <Box marginTop={'2px !important'}>{text}</Box>
                        </Flex>
                    </RouteLink>
                )}
                {as === 'button' && (
                    <Link onClick={onClick} {...mobile.linkItem}>
                        <Flex direction={'row'}>
                            <Box {...mobile.linkIcon}>{icon}</Box>
                            <Box marginTop={'2px !important'}>{text}</Box>
                        </Flex>
                    </Link>
                )}
            </Box>
        );
    };

    const links = [
        {
            href: AppRoutes.DASHBOARD,
            icon: <MobileDashboardSvg />,
            text: content('sidebar.dashboard'),
            active: loc === AppRoutes.DASHBOARD ? true : false,
        },
        {
            href: AppRoutes.BREACH,
            icon: <MobileMonitoringSvg />,
            text: content('sidebar.monitoring'),
            active: loc.indexOf(AppRoutes.BREACH) > -1 ? true : false,
        },
        {
            href: AppRoutes.ASSESSMENT,
            icon: <MobileAssessmentSvg />,
            text: content('sidebar.assessment'),
            active: loc.indexOf(AppRoutes.ASSESSMENT) > -1 ? true : false,
        },
        {
            href: AppRoutes.DISCOVER,
            icon: <MobileDiscoverySvg />,
            text: content('sidebar.discover'),
            active: loc === AppRoutes.DISCOVER ? true : false,
        },
    ];

    return (
        <>
            <Box id={'header-menu-mobile-toggle'} {...mobile.toggle}>
                <Link onClick={toggleMenu}>
                    <HamburgerIcon color={'white'} width={'24px'} height={'24px'} />
                </Link>
            </Box>
            {showMenu && (
                <Box id={'header-menu-mobile'} {...mobile.root}>
                    <Flex direction={'column'} gap={4}>
                        <Box pt={'10px'} pr={'10px'} textAlign={'right'}>
                            <Link onClick={toggleMenu}>
                                <CloseIcon color={'white'} />
                            </Link>
                        </Box>

                        {links.map((link, i) => {
                            const { href, icon, text, active } = link;
                            return (
                                <LinkItem
                                    key={`linkitem-${i}`}
                                    {...{ href, icon, text, active }}
                                />
                            );
                        })}

                        <Divider w={'80%'} margin={'0 auto'} />

                        {isFeatureEnabled(AppFeatures.PROFILE) && (
                            <LinkItem
                                href={AppRoutes.PROFILE}
                                icon={<MobileProfileSvg />}
                                text={accountInfo?.firstName}
                                active={loc === AppRoutes.PROFILE ? true : false}
                            />
                        )}

                        <LinkItem
                            as="button"
                            onClick={() => {
                                onSignOut();
                            }}
                            href={AppRoutes.PROFILE}
                            icon={<MobileSignoutSvg />}
                            text={content('sidebar.signout')}
                            active={false}
                        />
                    </Flex>
                </Box>
            )}
        </>
    );
};

export default MobileMenu;
