import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormLabel,
    HStack,
    Input,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SmartScanLogo from '../../assets/logos/SmartScanLogo';
import { AppConfig, AppRoutes } from '../../config/config';
import { NullableVar } from '../../config/interface';
import { account, authToken } from '../../entities/session';
import useContent from '../../hooks/useContent';
import { fetchAccountDetails } from '../../services/apiService';
import { isValidEmail } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';

export type LoginProps = {
    isAuthenticating?: NullableVar<boolean>;
    onRegister?: (email: string, token?: string) => any;
};

const Register = ({ onRegister, isAuthenticating = false }: LoginProps) => {
    const content = useContent();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');

    const submitHandler = e => {
        e.preventDefault();
        if (isValidEmail(email) && onRegister) {
            window.dataLayer.push({
                event: 'SecurityAdvisor_DL_Event Registration - Direct Access Email Entry',
                System: 'Security Advisor',
                Partner: AppConfig.carrier,
                Program: AppConfig.program,
                ID: '',
                // Timestamp: now(),
                Module: 'Registration - Direct Access',
                MainSubscriberEmail: email,
            });
            onRegister(email, token);
        } else {
            setError('Invalid email address');
        }
    };

    const prefillToken = searchParams.get('pre');

    const { isLoading } = useQuery({
        queryKey: ['prefill', prefillToken],
        queryFn: async () => {
            const { data: tokenData } = await axios.post<{
                token: string;
                registrationStatus?: string;
            }>(`${process.env.REACT_APP_API_BASE_URL}/register/token`, {
                source: 'myAsurionPreSignInUp',
                session: prefillToken,
            });

            if (tokenData?.registrationStatus === 'ALREADY_EXISTING_NONPRIMARY_EMAIL') {
                // TODO: Update action when this occurs
                throw new Error('ALREADY_EXISTING_NONPRIMARY_EMAIL');
            }

            const { data: prefillData } = await axios.get<{ email: string }>(
                `${process.env.REACT_APP_API_BASE_URL}/register/prefill`,
                {
                    headers: {
                        'x-security-advisor-authtoken': tokenData.token,
                    },
                }
            );

            if (tokenData?.registrationStatus === 'ALREADY_EXISTING_PRIMARY_EMAIL') {
                authToken.set(tokenData.token);
                const accountDetails = await fetchAccountDetails({
                    email: prefillData.email,
                });

                if (!accountDetails?.user) {
                    throw new Error(
                        'ALREADY_EXISTING_PRIMARY_EMAIL but no account details'
                    );
                }

                return {
                    ...tokenData,
                    accountDetails: accountDetails?.user,
                };
            }

            return {
                ...prefillData,
                ...tokenData,
            };
        },
        onSuccess: data => {
            if ('email' in data) {
                setEmail(data.email);
                setToken(data.token);
            } else if (data.token) {
                authToken.set(data.token);
                account.set(data.accountDetails);
                navigate(AppRoutes.DASHBOARD);
                window.dataLayer.push({
                    event: 'SecurityAdvisor_DL_Event Login Success',
                    System: 'Security Advisor',
                    Partner: AppConfig.carrier,
                    Program: AppConfig.program,
                    ID: '',
                    Module: 'Login OTP',
                    MainSubscriberEmail: email,
                });
            }
        },
        enabled: !!prefillToken,
    });

    if (isLoading) {
        return null;
    }

    return (
        <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
            <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                <HStack alignItems={'center'} mb={'10px'}>
                    <Text fontSize={24} fontWeight={700} marginRight={'7px'}>
                        {content('forms.registration.header.welcome')}
                    </Text>
                    <SmartScanLogo props={{ style: { margin: 0, minWidth: '172px' } }} />
                </HStack>
                <VStack spacing={2} alignItems={'baseline'}>
                    <Text fontSize="16px">
                        {content('forms.registration.header.content1')}
                    </Text>
                    <Text fontSize="16px">
                        {content('forms.registration.header.content2')}
                    </Text>
                </VStack>
            </CardHeader>
            <CardBody>
                <form onSubmit={submitHandler}>
                    <Stack spacing={1} fontSize={'14px'}>
                        <FormLabel fontWeight={700}>
                            {content('forms.registration.fields.email.label')}
                        </FormLabel>
                        <Input
                            type={'text'}
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                            placeholder={content(
                                'forms.registration.fields.email.placeholder'
                            )}
                        />
                        {error && <Text color={'red'}>{error}</Text>}
                        <Text fontSize={'12px'} color={'grayscale.200'}>
                            {content(
                                'forms.registration.fields.email.helper',
                                'Terms of Use and Privacy Policy',
                                (match, i) => (
                                    <span key={i}>
                                        <RouteLink
                                            to={
                                                AppConfig.termsOfUseUrl || AppRoutes.LOGIN
                                            }
                                            textDecor="underline"
                                            isExternal={true}
                                        >
                                            Terms of Use
                                        </RouteLink>
                                        &nbsp;and&nbsp;
                                        <RouteLink
                                            textDecor="underline"
                                            to={
                                                AppConfig.privacyPolicyUrl ||
                                                AppRoutes.LOGIN
                                            }
                                            isExternal={true}
                                        >
                                            Privacy Policy
                                        </RouteLink>
                                    </span>
                                )
                            )}
                        </Text>

                        <Button type="submit" isLoading={isAuthenticating || false}>
                            {content('forms.registration.fields.submit.label')}
                        </Button>
                    </Stack>
                </form>
            </CardBody>
        </Card>
    );
};

export default Register;
