import { Accordion } from '@chakra-ui/react';
import React from 'react';
import BreachAssetValueList from './BreachAssetValueList';

const BreachAssetValueItemGroup = ({
    groupings,
    groupBy,
    asset,
    email,
    mode,
    onResolved,
}: {
    groupings: [];
    groupBy: string;
    asset: string;
    email: string;
    mode: string;
    onResolved: Function;
}) => {
    let exposedItemGroupedByValue: any[] = [];
    if (groupings) {
        Object.keys(groupings).forEach((key, i) => {
            const maskedValue = key;
            const details = groupings[key];

            exposedItemGroupedByValue.push(
                <BreachAssetValueList
                    key={`breach-asset-value-list-${i}`}
                    maskedValue={maskedValue}
                    details={details}
                    index={i}
                    asset={asset}
                    email={email}
                    mode={mode}
                    onResolved={onResolved}
                />
            );
        });
    }

    return (
        <Accordion allowMultiple={true} p={0}>
            {exposedItemGroupedByValue}
        </Accordion>
    );
};

export default BreachAssetValueItemGroup;
