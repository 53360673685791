import * as React from 'react';

const AssetUsernamesSvg = props => (
    <svg width={49} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2.95 36c0-4.5 6-4.5 9-7.5 1.5-1.5-3-1.5-3-9 0-5 2-7.5 6-7.5s6 2.5 6 7.5c0 7.5-4.5 7.5-3 9 3 3 9 3 9 7.5"
            stroke="#111"
            strokeLinecap="round"
        />
        <path
            d="M24.94 32.486c1.134-.781 2.606-1.344 4.014-2.022 1.102-.53 2.164-1.132 2.996-1.964 1.5-1.5-3-1.5-3-9 0-5 2-7.5 6-7.5s6 2.5 6 7.5c0 7.5-4.5 7.5-3 9 3 3 9 3 9 7.5"
            stroke="#111"
            strokeLinecap="round"
        />
    </svg>
);

export default AssetUsernamesSvg;
