import * as React from 'react';

const MobileProfileSvg = props => (
    <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z"
            stroke="#fff"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M13 14.043a4.435 4.435 0 1 0 0-8.87 4.435 4.435 0 0 0 0 8.87Z"
            stroke="#fff"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.592 19.783a7.292 7.292 0 0 0-13.184 0"
            stroke="#fff"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default MobileProfileSvg;
