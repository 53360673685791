import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const CountBadge = ({ count }) => {
    return (
        <Box
            w={'28px'}
            h={'28px'}
            backgroundColor={'brand.primary.500'}
            color={'white'}
            borderRadius={'50%'}
            p={'5px'}
            textAlign={'center'}
        >
            <Text fontSize={'12px'} fontWeight={700}>
                {count || 0}
            </Text>
        </Box>
    );
};
export default CountBadge;
