import { useCallback, useMemo, useState } from 'react';

interface UseWizardOptions<T> {
    steps: T[];
    hasPre: boolean;
    hasPost: boolean;
}

const useWizard = <A = string, T = any>({
    steps = [],
    hasPre = false,
    hasPost = false,
}: Partial<UseWizardOptions<T>>) => {
    const [currStep, setCurrStep] = useState(0);
    const numSteps = useMemo(
        () => steps.length + +hasPre + +hasPost,
        [hasPost, hasPre, steps.length]
    );
    const hasNextStep = useMemo(() => currStep < numSteps - 1, [currStep, numSteps]);
    const hasPrevStep = useMemo(() => currStep > 0, [currStep]);
    const progress = useMemo(
        () => ((currStep + 1) / numSteps) * 100,
        [currStep, numSteps]
    );
    const isPre = useMemo(() => hasPre && currStep === 0, [currStep, hasPre]);
    const isPost = useMemo(
        () => hasPost && currStep === numSteps - 1,
        [currStep, hasPost, numSteps]
    );
    const [answers, setAnswers] = useState<A[]>([...Array(steps.length)]);

    const handleAnswer = useCallback(
        (answer: A) => {
            setAnswers(curr => {
                const idx = hasPre ? currStep - 1 : currStep;
                const newAns = [...curr];
                newAns[idx] = answer;

                return newAns;
            });
        },
        [currStep, hasPre]
    );

    const currStepData = useMemo(() => {
        if (hasPre) {
            if (currStep === 0) {
                return undefined;
            }

            return steps[currStep - 1];
        }

        return steps[currStep];
    }, [currStep, hasPre, steps]);

    const currAnswer = useMemo(() => {
        if (hasPre) {
            if (currStep === 0) {
                return undefined;
            }

            return answers[currStep - 1];
        }

        return answers[currStep];
    }, [answers, currStep, hasPre]);

    const handleNextStep = useCallback(
        (callback?: (step: number) => {}) => {
            if (hasNextStep) {
                const newStep = currStep + 1;
                if (callback) {
                    callback(newStep);
                }
                setCurrStep(newStep);
            }
        },
        [currStep, hasNextStep]
    );

    const handlePrevStep = useCallback(
        (callback?: (step: number) => {}) => {
            if (hasPrevStep) {
                const newStep = currStep - 1;
                if (callback) {
                    callback(newStep);
                }
                setCurrStep(newStep);
            }
        },
        [currStep, hasPrevStep]
    );

    const goToStep = useCallback(
        (step: number | 'pre' | 'post') => {
            if (typeof step === 'string') {
                if (step === 'post' && hasPost) {
                    setCurrStep(numSteps - 1);
                } else if (step === 'pre' && hasPre) {
                    setCurrStep(0);
                }
            } else if (step >= 0 && step < numSteps) {
                setCurrStep(step);
            }
        },
        [hasPost, hasPre, numSteps]
    );

    return {
        currStep,
        numSteps,
        hasNextStep,
        hasPrevStep,
        progress,
        answers,
        currStepData,
        currAnswer,
        isPre,
        isPost,
        handleNextStep,
        handlePrevStep,
        handleAnswer,
        goToStep,
    };
};

export default useWizard;
