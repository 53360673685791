import { useMutation } from '@tanstack/react-query';
import { logout } from '../services/apiService';
import { triggerSignOut } from '../utils/helpers';

const useLogout = () => {
    const { mutate: doLogout } = useMutation({
        mutationFn: logout,
        onSettled: () => {
            triggerSignOut();
        },
    });

    return doLogout;
};
export default useLogout;
