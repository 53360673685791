import { Button, Image, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { WizardCardBottom } from '../../common/Wizard/WizardCardBottom';
import { WizardCardTop, WizardCardTopHeading } from '../../common/Wizard/WizardCardTop';
import { WizardCard } from '../../common/Wizard/WizardCard';
import Logo from '../../../assets/images/SecurityAssessmentLogo.png';
import { CheckIcon } from '@chakra-ui/icons';

interface Props {
    onNext: () => void;
}

// TODO: Fetch  strings from contentful
const TestOverviewWizardCard: React.FC<Props> = ({ onNext }) => (
    <WizardCard>
        <WizardCardTop align="center" textAlign="center">
            <Image src={Logo} />
            <WizardCardTopHeading>How secure are you online?</WizardCardTopHeading>
            <Text>
                Find out your security score by answering a few questions about your
                current security habits.
            </Text>
        </WizardCardTop>
        <WizardCardBottom>
            <Stack spacing={4}>
                <Text>You'll also get:</Text>
                <List spacing={3} fontWeight="bold">
                    <ListItem>
                        <ListIcon as={CheckIcon} color="#008662" />
                        personalized security recommendations
                    </ListItem>

                    <ListItem>
                        <ListIcon as={CheckIcon} color="#008662" />
                        helpful security tips
                    </ListItem>
                </List>
                <Text>
                    Remember to answer honestly. This assessment takes about 5 minutes to
                    complete.
                </Text>
                <Button mt={4} onClick={() => onNext()}>
                    Start assessment
                </Button>
            </Stack>
        </WizardCardBottom>
    </WizardCard>
);

export default TestOverviewWizardCard;
