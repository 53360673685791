import {
    Box,
    TabList,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    HStack,
    Text,
    Flex,
    Spinner,
    Accordion,
} from '@chakra-ui/react';
import React from 'react';
import { AppConfig, ASSET_CRITERIA } from '../../config/config';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import CountBadge from '../common/CountBadge';
import BreachAssetTypeItem from './BreachAssetTypeItem';
import BreachAssetValueItemGroup from './BreachAssetValueItemGroup';

const BreachDetailsTab = ({
    email,
    asset,
    exposedItems,
    resolvedItems,
    isLoading,
    onResolved,
}) => {
    const content = useContent();
    const { tabs } = getPageStyles('BreachDetailsPage');
    const groupBy = AppConfig.assetGroupBy;

    return (
        <Box id={'breachpage-details-tab'} {...tabs.root}>
            <Tabs variant={'enclosed'}>
                <TabList>
                    <Tab
                        {...tabs.tabItem}
                        _selected={tabs.tabItemSelected}
                        isDisabled={isLoading}
                    >
                        <HStack>
                            <Text>Exposed</Text>
                            {!isLoading && exposedItems && exposedItems.length > 0 && (
                                <CountBadge count={exposedItems.length || 0} />
                            )}
                        </HStack>
                    </Tab>
                    <Tab
                        {...tabs.tabItem}
                        _selected={tabs.tabItemSelected}
                        isDisabled={isLoading}
                    >
                        <HStack>
                            <Text>Resolved</Text>
                            {!isLoading && resolvedItems && resolvedItems.length > 0 && (
                                <CountBadge count={resolvedItems.length || 0} />
                            )}
                        </HStack>
                    </Tab>
                </TabList>
                {isLoading && (
                    <Box
                        width={'100%'}
                        textAlign={'center'}
                        pt={'15%'}
                        backgroundColor={'white'}
                    >
                        <Spinner variant={'pageLoader'} />
                    </Box>
                )}
                {!isLoading && (
                    <TabPanels {...tabs.tabPanels}>
                        <TabPanel>
                            <Flex direction={'column'} gap={2}>
                                <Text {...tabs.tabPanelTitle}>
                                    {groupBy === 'value'
                                        ? content('breach.panelexposedgroupby')
                                        : content('breach.panelexposed')}
                                </Text>
                                {groupBy === 'value' && exposedItems && (
                                    <BreachAssetValueItemGroup
                                        asset={asset}
                                        email={email}
                                        groupings={exposedItems}
                                        groupBy={groupBy}
                                        mode={ASSET_CRITERIA.EXPOSED}
                                        onResolved={onResolved}
                                    />
                                )}
                                {!groupBy && (
                                    <Accordion allowMultiple={true}>
                                        {exposedItems?.map((exposed, i) => {
                                            const { title } = exposed;

                                            return (
                                                <BreachAssetTypeItem
                                                    key={`exposed-type-item-${i}`}
                                                    title={title}
                                                    asset={asset}
                                                    email={email}
                                                    assetItem={exposed}
                                                    mode={ASSET_CRITERIA.EXPOSED}
                                                    onResolved={onResolved}
                                                />
                                            );
                                        })}
                                    </Accordion>
                                )}
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <Flex direction={'column'}>
                                <Text {...tabs.tabPanelTitle}>
                                    {content('breach.panelresolved')}
                                </Text>
                                <Accordion allowMultiple={true}>
                                    {groupBy === 'value' && resolvedItems && (
                                        <BreachAssetValueItemGroup
                                            asset={asset}
                                            email={email}
                                            groupings={resolvedItems}
                                            groupBy={groupBy}
                                            mode={ASSET_CRITERIA.RESOLVED}
                                            onResolved={onResolved}
                                        />
                                    )}
                                    {!groupBy &&
                                        resolvedItems?.map((resolved, i) => {
                                            const { title } = resolved;

                                            return (
                                                <BreachAssetTypeItem
                                                    key={`resolved-type-item-${i}`}
                                                    title={title}
                                                    asset={asset}
                                                    email={email}
                                                    assetItem={resolved}
                                                    mode={ASSET_CRITERIA.RESOLVED}
                                                    onResolved={onResolved}
                                                />
                                            );
                                        })}
                                </Accordion>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                )}
            </Tabs>
        </Box>
    );
};

export default BreachDetailsTab;
