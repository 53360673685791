import { useToast, UseToastOptions } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

const useErrorToast = () => {
    const _toast = useToast();

    const toast = useCallback(
        (err?: AxiosError<Error>, options?: UseToastOptions) => {
            _toast({
                status: 'error',
                title: 'Error',
                description: err?.response?.data?.message || err?.message,
                ...options,
            });
        },
        [_toast]
    );

    return toast;
};

export default useErrorToast;
