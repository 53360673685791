import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Divider,
    Flex,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
} from '@chakra-ui/react';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ChevronDownBlackSvg from '../../assets/ChevronDownBlackSvg';
import OverviewBreachMonitoringSvg from '../../assets/OverviewBreachMonitoringSvg';
import { account } from '../../entities/session';
import {
    clippedString,
    emailSplitter,
    getResponseError,
    isMobileScreen,
    lastScanFormat,
} from '../../utils/helpers';
import OverviewCard, {
    OverviewFooterLink,
    OverviewTitleCard,
} from '../common/OverviewCard';
import { IAccountEmail, IAssets, NullableVar } from '../../config/interface';
import WarningRedSvg from '../../assets/WarningRedSvg';
import { useQuery } from '@tanstack/react-query';
import { fetchAllAssets } from '../../services/apiService';
import { breachAssets, exposedCount as exposedCountEntity } from '../../entities/assets';
import { getWidgetStyles } from '../../config/theme';
import ShieldGreenSvg from '../../assets/ShieldGreenSvg';
import { AppRoutes } from '../../config/config';
import RouteLink from '../common/RouteLink';
import useLogout from '../../hooks/useLogout';
import useContent from '../../hooks/useContent';

const BreachMonitoringWidget = () => {
    const content = useContent();
    const doLogout = useLogout();
    const [primaryEmail, setPrimaryEmail] = useState<IAccountEmail>({
        email: '',
        lastScanDate: 0,
    });
    const [selectedEmail, setSelectedEmail] = useState<IAccountEmail>({
        email: '',
        lastScanDate: 0,
    });
    const [selectedLastScanDate, setSelectedLastScanDate] =
        useState<NullableVar<number>>();
    const [assets, setAssets] = useState<any>();
    const [exposedCount, setExposedCount] = useState<NullableVar<number>>(0);
    const [accordionIndex, setAccordionIndex] = useState<number>(
        isMobileScreen() ? -1 : 0
    );
    const [isSelectedEmailPrimary, setIsSelectedEmailPrimary] =
        useState<NullableVar<boolean>>(true);

    const accountInfo = account.use();

    const {
        summary,
        subcategory,
        accordionItemButton,
        category,
        accordionItem,
        accordionPanel,
        emailSelector,
    } = getWidgetStyles('Breach');

    const { isFetching: assetFetching } = useQuery<IAssets>(
        ['assets', selectedEmail?.email || primaryEmail?.email],
        () => {
            return fetchAllAssets({ email: selectedEmail?.email || primaryEmail?.email });
        },
        {
            enabled: selectedEmail ? true : false,
            onSuccess: assetData => {
                if (assetData) {
                    setAssets(assetData?.assets || {});
                    breachAssets.set(assetData);
                    setExposedCount(assetData?.exposed);
                    setPrimaryEmail({
                        ...primaryEmail,
                        lastScanDate: assetData.lastScanDate || 0,
                    });
                    setSelectedEmail(selectedEmail || primaryEmail);
                    setSelectedLastScanDate(assetData.lastScanDate);
                    exposedCountEntity.set(assetData?.exposed);

                    // update account
                    if (isSelectedEmailPrimary) {
                        accountInfo.secondaryEmails = assetData?.secondaryEmail;
                        account.set(accountInfo);
                    }
                }
            },
            onError: assetError => {
                const { status } = getResponseError(assetError);
                if (status === 401) {
                    doLogout();
                }
            },
        }
    );

    const onEmailChange = useCallback(
        (email, lastScanDate, isPrimary) => {
            if (selectedEmail.email !== email && email) {
                setAssets(undefined);
                setSelectedEmail({ email, lastScanDate });
                setSelectedLastScanDate(lastScanDate);
                setIsSelectedEmailPrimary(isPrimary);
            }
        },
        [selectedEmail]
    );

    useEffect(() => {
        if (accountInfo && primaryEmail.email === '') {
            setPrimaryEmail(
                accountInfo?.primaryEmail?.email
                    ? accountInfo?.primaryEmail
                    : { email: accountInfo?.primaryEmail, lastScanDate: 0 }
            );
        }

        if (selectedEmail && !selectedEmail.email) {
            setSelectedEmail(accountInfo?.primaryEmail);
            setSelectedLastScanDate(accountInfo?.primaryEmail?.lastScanDate);
        }
    }, [accountInfo, primaryEmail, selectedEmail, assets, assetFetching, doLogout]);

    const EmailSelector = () => {
        const clippedLength = isMobileScreen() ? 25 : 33;

        return (
            <>
                <Menu>
                    <MenuButton {...emailSelector.root}>
                        <Flex direction={'row'} gap={2} verticalAlign={'middle'}>
                            <Text {...emailSelector.placeholder}>
                                {clippedString(
                                    selectedEmail?.email
                                        ? selectedEmail?.email
                                        : content('widgets.breach.primaryEmail'),
                                    clippedLength
                                )}
                            </Text>
                            <Spacer />
                            <ChevronDownBlackSvg />
                        </Flex>
                    </MenuButton>
                    <MenuList {...emailSelector.list}>
                        {primaryEmail?.email && (
                            <MenuItem
                                onClick={() => {
                                    if (selectedEmail.email !== primaryEmail.email) {
                                        onEmailChange(
                                            primaryEmail.email,
                                            primaryEmail.lastScanDate,
                                            true
                                        );
                                    }
                                }}
                            >
                                {primaryEmail?.email}
                            </MenuItem>
                        )}
                        {accountInfo && accountInfo?.secondaryEmails?.length > 0 && (
                            <Divider></Divider>
                        )}

                        {accountInfo &&
                            accountInfo?.secondaryEmails?.map((item, index) => {
                                const { email, lastScanDate } = item;
                                return (
                                    <MenuItem
                                        key={`email-select-${index}`}
                                        onClick={() =>
                                            onEmailChange(email, lastScanDate, false)
                                        }
                                    >
                                        {email}
                                    </MenuItem>
                                );
                            })}
                    </MenuList>
                </Menu>
            </>
        );
    };

    const EmailWithLastScanDate = () => {
        return (
            <Box id={'overview-breach-selected-email'} mt={'16px'} mb={'16px'}>
                <Heading fontSize={'18px'} fontWeight={'700'}>
                    <Text as={'span'} textTransform={'capitalize'}>
                        {emailSplitter(selectedEmail?.email || primaryEmail?.email)[0]}
                    </Text>
                    @{emailSplitter(selectedEmail?.email || primaryEmail?.email)[1]}
                </Heading>
                {
                    <Heading fontSize={'14px'} fontWeight={'400'}>
                        last scan {lastScanFormat(selectedLastScanDate)}
                    </Heading>
                }
            </Box>
        );
    };

    const BreachSummary = () => {
        return (
            <Box id={'overview-breach-summary'} {...summary}>
                <Flex direction={'column'} gap={2}>
                    <Accordion allowToggle={true} index={accordionIndex}>
                        {assets &&
                            Object.keys(assets).map((groupName, groupIndex) => {
                                const items = assets[groupName];
                                let SubCategory: ReactElement[] = [];
                                let itemExposedCount = 0;

                                for (let x = 0; x < items.length; x++) {
                                    const { name, exposed } = items[x];
                                    if (exposed > 0) {
                                        itemExposedCount = itemExposedCount + exposed;
                                    }
                                    SubCategory.push(
                                        <Box
                                            mb={'4px'}
                                            mt={'4px'}
                                            key={`subcategory-${name}-${x}`}
                                        >
                                            <Flex direction={'row'} gap={2}>
                                                {exposed > 0 ? (
                                                    <WarningRedSvg />
                                                ) : (
                                                    <ShieldGreenSvg />
                                                )}
                                                <Heading {...subcategory}>
                                                    {name
                                                        ?.toLowerCase()
                                                        .replaceAll('_', ' ')}
                                                </Heading>
                                            </Flex>
                                        </Box>
                                    );
                                }

                                return (
                                    <AccordionItem
                                        {...accordionItem}
                                        key={`accordion-assetgroup-${groupName.toLowerCase()}`}
                                    >
                                        <AccordionButton
                                            {...accordionItemButton}
                                            onClick={() => {
                                                setAccordionIndex(groupIndex);
                                            }}
                                        >
                                            <Flex direction={'row'} w={'100%'}>
                                                <Box>
                                                    <Heading {...category}>
                                                        {groupName}&nbsp;(
                                                        {itemExposedCount})
                                                    </Heading>
                                                </Box>
                                                <Spacer />
                                                <ChevronDownBlackSvg />
                                            </Flex>
                                        </AccordionButton>
                                        <AccordionPanel {...accordionPanel}>
                                            <Flex direction={'column'}>
                                                {SubCategory}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                    </Accordion>
                </Flex>
            </Box>
        );
    };

    return (
        <OverviewCard
            id={'breach'}
            isLoading={assetFetching}
            title={
                <OverviewTitleCard
                    titleIcon={<OverviewBreachMonitoringSvg />}
                    titleText={content('widgets.breach.title')}
                />
            }
            footer={
                <Box textAlign={'center'} w={'100%'}>
                    <OverviewFooterLink onClick={() => {}}>
                        <RouteLink to={AppRoutes.BREACH}>View all breaches</RouteLink>
                    </OverviewFooterLink>
                </Box>
            }
        >
            <Box id={'overview-breach-body'}>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems={'baseline'}
                    wrap={'wrap'}
                    gap={2}
                >
                    <EmailSelector />
                    <Spacer />

                    {exposedCount ? (
                        <Badge colorScheme={'red'} variant={'breachCount'}>
                            <>{exposedCount}&nbsp;breaches</>
                        </Badge>
                    ) : (
                        <></>
                    )}
                </Flex>

                {selectedLastScanDate && selectedLastScanDate > 0 && (
                    <EmailWithLastScanDate />
                )}
                <hr style={{ borderStyle: 'dashed', color: 'black' }} />
                <BreachSummary />
            </Box>
        </OverviewCard>
    );
};

export default BreachMonitoringWidget;
