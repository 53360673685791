import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    Spacer,
    Spinner,
    VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import EyeClosedSvg from '../../assets/EyeClosedSvg';
import EyeOpenSvg from '../../assets/EyeOpenSvg';
import { IAssetTypeUnmaskedResponse, NullableVar } from '../../config/interface';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import useLogout from '../../hooks/useLogout';
import { unmaskedValue } from '../../services/apiService';
import { clippedString, getResponseError, isMobileScreen } from '../../utils/helpers';
import BreachAssetValueItem from './BreachAssetValueItem';

const BreachAssetValueList = ({
    index,
    maskedValue,
    details,
    asset,
    email,
    mode,
    onResolved,
}: {
    index: number;
    maskedValue: string;
    details: any[];
    asset: string;
    email: string;
    mode: string;
    onResolved: Function;
}) => {
    const { groupedItems } = getPageStyles('BreachDetailsPage');
    const content = useContent();
    const doLogout = useLogout();
    // const [expanded, setExpanded] = useState<boolean>(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState<number>(0);
    const [unmasked, setUnmasked] = useState<NullableVar<string>>(null);
    const clippedLength = isMobileScreen() ? 30 : 70;

    const {
        data: unmaskedData,
        error: unmaskedError,
        isFetching: unmaskedFetching,
    } = useQuery<IAssetTypeUnmaskedResponse>(
        ['unmasked-value', email, asset, selectedDocumentId],
        () => unmaskedValue({ email, asset, documentId: selectedDocumentId }),
        {
            enabled: email && email && asset && selectedDocumentId ? true : false,
        }
    );

    useEffect(() => {
        // unmasking
        if (unmaskedData && unmasked === null) {
            setUnmasked(unmaskedData.unmaskedItem);
        }

        if (!unmaskedFetching && unmaskedError) {
            const { status } = getResponseError(unmaskedError);
            if (status === 401) {
                doLogout();
            }
        }
    }, [unmaskedData, unmasked, unmaskedError, unmaskedFetching, doLogout]);

    return (
        <AccordionItem
            {...groupedItems.root}
            key={`item-group-by-${index}`}
            className={`item-group-by`}
            id={`item-group-by-${index}`}
        >
            {({ isExpanded }) => {
                return (
                    <>
                        <AccordionButton
                            {...groupedItems.button}
                            _hover={{
                                backgroundColor: 'transparent',
                            }}
                        >
                            <Box {...groupedItems.expandIcon}>
                                {' '}
                                {!isExpanded ? <AddIcon /> : <MinusIcon />}
                            </Box>
                            <HStack {...groupedItems.maskedBox}>
                                <Box w={'95%'}>
                                    {!unmasked && (
                                        <Box float={'left'}>
                                            {clippedString(maskedValue, clippedLength)}
                                        </Box>
                                    )}
                                    {unmasked && (
                                        <Box float={'left'}>
                                            {clippedString(unmasked, clippedLength)}
                                        </Box>
                                    )}
                                </Box>
                                <Spacer
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                />
                                <Box
                                    cursor={'pointer'}
                                    float={'right'}
                                    onClick={e => {
                                        e.preventDefault();
                                        if (details && details.length > 0) {
                                            const documentId = details[0]?.documentId;
                                            if (unmasked === null) {
                                                setSelectedDocumentId(documentId);
                                            } else {
                                                setSelectedDocumentId(0);
                                                setUnmasked(null);
                                            }
                                        }
                                    }}
                                >
                                    {unmaskedFetching ? (
                                        <Spinner variant={'miniLoader'} />
                                    ) : unmasked ? (
                                        <Box mt={'5px'}>
                                            <EyeOpenSvg />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <EyeClosedSvg />
                                        </Box>
                                    )}
                                </Box>
                            </HStack>
                        </AccordionButton>
                        <AccordionPanel p={'8px 0 8px 60px'}>
                            <VStack
                                alignItems={'left'}
                                minW={{ base: '330px', md: '708px' }}
                            >
                                <Box fontWeight={'700'} overflowWrap={'break-word'}>
                                    {content('breach.exposedvaluegroupby')}
                                </Box>
                                <Box>
                                    {details &&
                                        details?.map((item: any, i: number) => {
                                            const title =
                                                item && item?.title ? item?.title : '';

                                            return (
                                                <BreachAssetValueItem
                                                    key={`breach-asset-value-item-${i}`}
                                                    title={title}
                                                    asset={asset}
                                                    email={email}
                                                    mode={mode}
                                                    assetItem={item}
                                                    onResolved={onResolved}
                                                />
                                            );
                                        })}
                                </Box>
                            </VStack>
                        </AccordionPanel>
                    </>
                );
            }}
        </AccordionItem>
    );
};

export default BreachAssetValueList;
