import React, { ReactElement, ReactNode } from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    Skeleton,
    Spinner,
    StyleProps,
} from '@chakra-ui/react';
import VerticalCenter from '../layouts/VerticalCenter';
import ArrowGoToLinkSvg from '../../assets/ArrowGoToLinkSvg';
import { getComponentStyles } from '../../config/theme';
import { LOADER_TYPE } from '../../config/config';

export const OverviewTitleCard = ({
    titleIcon,
    titleText,
}: {
    titleIcon?: ReactNode;
    titleText: string;
}) => {
    return (
        <Flex direction={'row'}>
            <Box>{titleIcon}</Box>
            <Box pt={'3px'} pl={'2px'}>
                {titleText}
            </Box>
        </Flex>
    );
};

export const OverviewFooterLink: React.FC<{
    onClick: Function;
    children: ReactElement;
}> = ({ onClick, children }) => {
    return (
        <Box textAlign={'center'} w={'100%'}>
            <Button variant={'link'} color={'#111'} rightIcon={<ArrowGoToLinkSvg />}>
                {children}
            </Button>
        </Box>
    );
};

interface Props {
    id: string;
    isLoading?: boolean;
    title?: ReactNode;
    children: ReactNode;
    footer?: ReactNode;
    isHighlighted?: boolean;
    loadingType?: string;
    customProps?: StyleProps;
    customHeaderProps?: StyleProps;
    customBodyProps?: StyleProps;
    customFooterProps?: StyleProps;
}

const OverviewCard: React.FC<Props> = ({
    id,
    isLoading = false,
    loadingType = LOADER_TYPE.SPINNER,
    isHighlighted = false,
    title = null,
    children = <Box>Lorem Ipsum Dolor</Box>,
    footer = null,
    customProps = {},
    customBodyProps = {},
    customFooterProps = {},
    customHeaderProps = {},
}) => {
    const { header, root } = getComponentStyles('OverviewCard');
    const rootStyle = isHighlighted ? root.highlight : root.default;
    const headerStyle = isHighlighted ? header.highlight : header.default;

    const MainCard: React.FC<{ children: ReactNode }> = ({ children }) => {
        return (
            <Card
                id={`overviewcard-${id}`}
                variant={'outline'}
                {...rootStyle}
                {...customProps}
            >
                {title && (
                    <CardHeader {...headerStyle} {...customHeaderProps}>
                        {title}
                    </CardHeader>
                )}
                <CardBody {...customBodyProps}>{children}</CardBody>
                {footer && (
                    <CardFooter borderTop={'1px dashed #DDD'} {...customFooterProps}>
                        {footer}
                    </CardFooter>
                )}
            </Card>
        );
    };

    return (
        <>
            {loadingType === LOADER_TYPE.SPINNER && (
                <MainCard>
                    <>
                        {isLoading && loadingType === 'spinner' && (
                            <VerticalCenter
                                innerProps={{
                                    w: 'auto',
                                    m: '0',
                                    borderRadius: '8px',
                                }}
                                h={'100%'}
                            >
                                <Spinner variant={'componentLoader'} />
                            </VerticalCenter>
                        )}
                        {!isLoading && children}
                    </>
                </MainCard>
            )}
            {loadingType === LOADER_TYPE.SKELETON && isLoading && (
                <Skeleton minH={'100px'} height={'100%'} {...rootStyle} />
            )}
            {loadingType === LOADER_TYPE.SKELETON && !isLoading && (
                <MainCard>{children}</MainCard>
            )}
        </>
    );
};

export default OverviewCard;
