import * as React from 'react';

const MobileAssessmentSvg = props => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
            <path d="M15 3h3v0a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H6v0a1 1 0 0 1-1-1V4v0a1 1 0 0 1 1-1h3" />
            <rect x={15} y={2} width={2} height={6} rx={1} transform="rotate(90 15 2)" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default MobileAssessmentSvg;
