import { Switch } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';

const StateSwitch = ({
    isChecked = false,
    onChange = () => {},
    disabled = false,
}: {
    isChecked: boolean;
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <Switch
            onChange={onChange}
            isChecked={isChecked}
            sx={{
                'span.chakra-switch__track[data-checked]': {
                    backgroundColor: 'brand.primary.500',
                },
            }}
        />
    );
};

export default StateSwitch;
