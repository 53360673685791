import React from 'react';
import { Box, Center, Flex, Spacer, Text } from '@chakra-ui/react';
import { getWidgetStyles } from '../../config/theme';
import { RISK_LEVEL } from '../../config/config';

const Graph = ({ score, risk, isCurrent = false }) => {
    const { graph } = getWidgetStyles('Security');

    return (
        <Box
            h={`${score}px`}
            maxH={'100px'}
            w={'40px'}
            backgroundColor={isCurrent ? graph.colorScale[risk.toUpperCase()] : '#E6E6EB'}
            borderRadius={'4px'}
            position={'relative'}
        >
            <Text
                fontSize={'12px'}
                fontWeight={700}
                transform={'rotate(-90deg)'}
                position={'absolute'}
                bottom={'5px'}
                left={'10px'}
                color={'black'}
            >
                {score}
            </Text>
        </Box>
    );
};

const VerticalGraph = ({ title = 'score', labels = [], scores = [] }) => {
    let newLabels: any = [];
    let newScores: any = [];

    // if (labels.length < 3) {
    //     for (let x = 0; x < 3; x++) {
    //         let label: any = labels[x] || '';
    //         newLabels.push(label ? label : '');
    //     }
    // } else {
    //     newLabels = labels;
    // }

    // if (scores.length < 3) {
    //     for (let x = scores.length - 1; x > 3; x++) {
    //         let score: any = scores[x] || '';
    //         newScores.push(score ? score : { score: 0, risk: RISK_LEVEL.HIGH });
    //     }
    // } else {
    //     newScores = scores;
    // }

    const label1 = labels[labels.length - 3] || '';
    const label2 = labels[labels.length - 2] || '';
    const label3 = labels[labels.length - 1] || '';
    newLabels.push(label1);
    newLabels.push(label2);
    newLabels.push(label3);

    const score1 = scores[scores.length - 3] || { score: 0, risk: RISK_LEVEL.HIGH };
    const score2 = scores[scores.length - 2] || { score: 0, risk: RISK_LEVEL.HIGH };
    const score3 = scores[scores.length - 1] || { score: 0, risk: RISK_LEVEL.HIGH };
    newScores.push(score1);
    newScores.push(score2);
    newScores.push(score3);

    return (
        <Box width={'103px'} height={'159px'}>
            <Flex direction={'column'} gap={0}>
                <Box borderBottom={'1px dotted gray'} h={'28px'}>
                    <Flex direction={'row'}>
                        {newLabels.map((label, index) => {
                            return (
                                <Box key={`chart-label-${index}`} w={'31px'}>
                                    <Center>
                                        <Text fontSize={'12px'} fontWeight={700}>
                                            {label}
                                        </Text>
                                    </Center>
                                    <Spacer />
                                </Box>
                            );
                        })}
                    </Flex>
                </Box>
                <Box h={'110px'}>
                    <Flex direction={'row'} alignItems={'self-end'} gap={1} h={'110px'}>
                        {newScores.map((data: any, index) => {
                            const isCurrent = index === scores.length - 1 ? true : false;
                            return (
                                <Graph
                                    score={data.score}
                                    risk={data.risk}
                                    isCurrent={isCurrent}
                                    key={`graph-${index}`}
                                />
                            );
                        })}
                    </Flex>
                </Box>
                <Spacer />
                <Box h={'15px'}>
                    <Center>
                        <Text
                            fontSize={'12px'}
                            fontWeight={400}
                            textTransform={'capitalize'}
                        >
                            {title}
                        </Text>
                    </Center>
                </Box>
            </Flex>
        </Box>
    );
};

export default VerticalGraph;
