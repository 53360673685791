import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const WarningGrayIcon: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 26 26" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.9974 12.7938C25.0142 19.4612 19.6654 24.9019 12.9987 24.9987C9.83841 25.0462 6.79069 23.8262 4.53617 21.611C2.28166 19.3958 1.00815 16.3701 1.00005 13.2094C0.981423 6.54082 6.33079 1.09808 12.9987 1.00132C16.1595 0.954063 19.2076 2.17464 21.4621 4.39045C23.7166 6.60626 24.9899 9.6327 24.9974 12.7938Z"
            fill="#A5AAAF"
            stroke="#A5AAAF"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.9987 15.1873V7.55176"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.9889 17.3682C12.9168 17.3693 12.8483 17.3994 12.7988 17.4518C12.7493 17.5042 12.723 17.5743 12.726 17.6463C12.7313 17.7951 12.8531 17.9131 13.002 17.9136V17.9136C13.0739 17.9121 13.1423 17.8819 13.1917 17.8296C13.2411 17.7773 13.2675 17.7073 13.2648 17.6354C13.2603 17.4901 13.1439 17.3733 12.9987 17.3682H12.9932"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);

export default WarningGrayIcon;
