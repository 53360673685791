import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ProfilePageIcon from '../../assets/ProfilePageIcon';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';

const ProfileWelcome = () => {
    const content = useContent();
    const { welcome } = getPageStyles('ProfilePage');

    return (
        <Flex id={`profilepage-welcome`} direction={'column'} {...welcome.root}>
            <Box display={{ base: 'flex', md: 'none' }} mb={'10px'}>
                <ProfilePageIcon />
            </Box>
            <Box>
                <Text {...welcome.title}>Profile</Text>
            </Box>
            <Box>
                <Text {...welcome.subtitle}>{content('profile.subtitle')}</Text>
            </Box>
        </Flex>
    );
};

export default ProfileWelcome;
