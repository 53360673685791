import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

const Wizard: React.FC<FlexProps> = ({ children, ...props }) => {
    return (
        <Flex
            w="full"
            minH="80vh"
            h="full"
            align="center"
            justify="center"
            px={{ base: 4 }}
            {...props}
        >
            {children}
        </Flex>
    );
};

export default Wizard;
