import * as React from 'react';

const AssetPassportSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M37.187 9.128 28.4 14.4l-16-4-2 2 11 9-5 7h-6l-2 2 5 3 2 2 3 5 2-2v-6l7-5 9 11 2-2-4-16 5.272-8.787a1.811 1.811 0 0 0-2.485-2.485Z"
            stroke="#111"
        />
    </svg>
);

export default AssetPassportSvg;
