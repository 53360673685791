import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { getPageStyles } from '../../config/theme';
import ProfileEmailsListMobile from './ProfileEmailsListMobile';
import ProfileEmailsListDesktop from './ProfileEmailsListDesktop';
import { AppFeatures } from '../../config/config';
import { isFeatureEnabled } from '../../utils/helpers';
import { useOutletContext } from 'react-router-dom';
import useContent from '../../hooks/useContent';

const ProfileEmailsList = ({
    profile,
    onToggleMonthlyReportSuccess,
    onToggleConfirmEmailDelete,
    isDeletingSecondaryEmail,
}) => {
    const content = useContent();
    const { emails } = getPageStyles('ProfilePage');
    const { primaryEmail, secondaryEmails } = profile;

    const available =
        profile && profile.availableEmailsCount ? profile.availableEmailsCount : 0;

    // const [openAddEmail, setOpenAddEmail] = useOutletContext<any>();
    const {
        addEmail: [openAddEmail, setOpenAddEmail],
    } = useOutletContext<any>();

    return (
        <Flex id={`profilepage-emailslist`} direction={'column'} {...emails.root}>
            <Box>
                <Text {...emails.title}>Your emails</Text>
            </Box>
            <Box>
                <Text {...emails.subtitle}>{content('profile.emailsubtitle')}</Text>
            </Box>

            <ProfileEmailsListMobile
                primaryEmail={primaryEmail}
                secondaryEmails={secondaryEmails}
                profile={profile}
                onToggleMonthlyReportSuccess={onToggleMonthlyReportSuccess}
                onToggleConfirmEmailDelete={onToggleConfirmEmailDelete}
                isDeletingSecondaryEmail={isDeletingSecondaryEmail}
            />

            <ProfileEmailsListDesktop
                primaryEmail={primaryEmail}
                secondaryEmails={secondaryEmails}
                profile={profile}
                onToggleMonthlyReportSuccess={onToggleMonthlyReportSuccess}
                onToggleConfirmEmailDelete={onToggleConfirmEmailDelete}
                isDeletingSecondaryEmail={isDeletingSecondaryEmail}
            />

            {isFeatureEnabled(AppFeatures.ADD_SECONDARY_EMAIL) && (
                <Box textAlign={'center'} maxW={'1024px'}>
                    {available > 0 && (
                        <Button
                            variant={'outline'}
                            {...emails.btnadd}
                            onClick={() => {
                                setOpenAddEmail(!openAddEmail);
                            }}
                            mb={'10px'}
                        >
                            Add another email
                        </Button>
                    )}
                    <Text mb={'24px'} fontSize={'14px'} color={'grayscale.200'}>
                        {available}&nbsp;{available > 1 ? 'emails' : 'email'}
                        &nbsp;remaining
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

export default ProfileEmailsList;
