import { Box, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { pageContents } from '../../entities/contents';
import useErrorToast from '../../hooks/useErrorToast';
import { fetchPageContents } from '../../services/apiService';
import { isPublicPage } from '../../utils/helpers';
import VerticalCenter from './VerticalCenter';

const Shell: React.FC<{ children: ReactElement }> = ({ children }) => {
    const appContents = pageContents.use();
    const [useDefaultContent, setUseDefaultContent] = useState(false);
    const location = useLocation();
    const errToast = useErrorToast();

    const useFetchPageContents = () =>
        useQuery(['contents'], {
            queryFn: () => fetchPageContents(),
            onSuccess: contents => {
                pageContents.set(contents);
            },
            onError: () => {
                setUseDefaultContent(true);
            },
            staleTime: 10 * (60 * 1000), // 10 mins
            cacheTime: 15 * (60 * 1000), // 15 mins
            enabled: isPublicPage(location.pathname) ? true : appContents ? false : true,
        });

    const useFetchDefaultContents = () =>
        useQuery(['defaultContents'], {
            queryFn: () => fetchPageContents('asurion'),
            onSuccess: contents => {
                pageContents.set(contents);
            },
            onError: (error: any) => {
                errToast(error);
            },
            staleTime: 10 * (60 * 1000), // 10 mins
            cacheTime: 15 * (60 * 1000), // 15 mins
            enabled: useDefaultContent,
        });

    const { isFetching: contentFetching } = useFetchPageContents();
    const { isFetching: defaultFetching } = useFetchDefaultContents();

    return (
        <Box id={'shell-container'}>
            {(contentFetching || defaultFetching) && (
                <VerticalCenter
                    innerProps={{
                        w: 'auto',
                        m: '20% auto',
                        borderRadius: '8px',
                    }}
                    h={'100%'}
                >
                    <Spinner variant={'pageLoader'} />
                </VerticalCenter>
            )}
            {!contentFetching && !defaultFetching && appContents && children}
        </Box>
    );
};

export default Shell;
