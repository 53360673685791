import { ButtonProps, Flex } from '@chakra-ui/react';
import {
    WizardCardBottom,
    WizardCardBottomHeading,
    WizardCardStepperActions,
} from '../../common/Wizard/WizardCardBottom';
import { WizardCardTop } from '../../common/Wizard/WizardCardTop';
import { WizardCard } from '../../common/Wizard/WizardCard';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import renderDocument from '../../../utils/renderDocument';

interface Props {
    topTitle: string;
    topDescription: Document;
    progress: number;
    bottomTitle: string;
    bottomDescription: Document;
    prevButtonProps: ButtonProps;
    nextButtonProps: ButtonProps;
}

export function GuideOverviewWizardCard({
    topTitle,
    topDescription,
    progress,
    bottomTitle,
    bottomDescription,
    prevButtonProps,
    nextButtonProps,
}: Partial<Props>) {
    return (
        <WizardCard>
            <WizardCardTop
                title={topTitle}
                description={topDescription}
                step="Overview"
                progress={progress}
            />
            <WizardCardBottom>
                {bottomTitle && (
                    <WizardCardBottomHeading>{bottomTitle}</WizardCardBottomHeading>
                )}
                {renderDocument(bottomDescription, {
                    renderNode: {
                        [BLOCKS.DOCUMENT]: (node, children) => (
                            <Flex flexDir="column" gap={1}>
                                {children}
                            </Flex>
                        ),
                    },
                })}
                <WizardCardStepperActions
                    prevButtonProps={prevButtonProps}
                    nextButtonProps={{
                        ...nextButtonProps,
                        text: 'Start',
                    }}
                />
            </WizardCardBottom>
        </WizardCard>
    );
}
