import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { IAccountEmail } from '../../config/interface';
import { getPageStyles } from '../../config/theme';
import useContent from '../../hooks/useContent';
import { lastScanFormat } from '../../utils/helpers';
import BreachMobileSelect from './BreachMobileSelect';

const BreachWelcomeBoard = ({
    title = <>Data Breach Monitoring</>,
    rescannable = true,
    selectable = false,
    email,
    emails,
    pageIcon = null,
    showAddEmail = false,
    rootStyle = {},
    onEmailChange = () => {},
    onRescan = () => {},
}: {
    title?: any;
    rescannable?: boolean;
    selectable?: boolean;
    email?: IAccountEmail;
    emails?: IAccountEmail[];
    showAddEmail?: boolean;
    pageIcon?: any;
    rootStyle?: any;
    onEmailChange?: Function;
    onRescan?: Function;
}) => {
    const content = useContent();

    const { welcome } = getPageStyles('BreachPage');

    return (
        <Flex
            id={'breachpage-welcome'}
            direction={{ base: 'column', md: 'row' }}
            gap={4}
            {...welcome.root}
            {...rootStyle}
        >
            <Flex direction={'column'}>
                <Box display={{ base: 'display', md: 'none' }}>{pageIcon}</Box>
                <Heading {...welcome.title}>{title}</Heading>
                {emails && emails.length > 0 && (
                    <BreachMobileSelect
                        selectedEmail={email}
                        emails={emails}
                        onEmailChange={onEmailChange}
                        showAddEmail={showAddEmail}
                    />
                )}
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    gap={{ base: 0, md: 2 }}
                    mt={{ base: '8px', md: 0 }}
                >
                    <Text {...welcome.subtitle1}>Scan results for:</Text>
                    <Text {...welcome.subtitle1} fontWeight={700}>
                        {email?.email}
                    </Text>
                </Flex>
                {email?.lastScanDate && email?.lastScanDate > 0 && (
                    <Text {...welcome.subtitle2}>
                        last scan {lastScanFormat(email?.lastScanDate)}
                    </Text>
                )}
            </Flex>
            {rescannable && (
                <Box>
                    <Button variant={'solid'} {...welcome.button} onClick={onRescan}>
                        {content('breach.scanthisemail')}
                    </Button>
                </Box>
            )}
        </Flex>
    );
};

export default BreachWelcomeBoard;
