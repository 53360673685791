import { Box, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { getPageStyles } from '../../config/theme';
import OverviewCard from '../common/OverviewCard';
import ProfileEmailDisplay from './ProfileEmailDisplay';

const ProfileEmailsListDesktop = ({
    profile,
    primaryEmail,
    secondaryEmails,
    onToggleMonthlyReportSuccess,
    onToggleConfirmEmailDelete,
    isDeletingSecondaryEmail,
}) => {
    const { card } = getPageStyles('ProfilePage');

    const tableHeaders = [
        { label: 'tag' },
        { label: 'name' },
        { label: 'date added' },
        { label: 'last scanned' },
        { label: 'monthly report' },
        { label: '' },
    ];

    return (
        <Box pt={'16px'} display={{ base: 'none', md: 'flex' }}>
            <OverviewCard
                id={'emaillists-desktop'}
                customProps={card.desktop.root}
                customBodyProps={card.desktop.body}
                customFooterProps={card.desktop.footer}
                footer={<Box p={0}>&nbsp;</Box>}
            >
                <>
                    <TableContainer backgroundColor={'white'} overflowX={'clip'}>
                        <Table variant="simple">
                            <Thead backgroundColor={'grayscale.400'}>
                                <Tr>
                                    {tableHeaders?.map((header, i) => {
                                        return (
                                            <Th
                                                key={`table-header-${i}`}
                                                textTransform={'capitalize'}
                                                color={'#111'}
                                                fontSize={'14px'}
                                            >
                                                {header?.label}
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {primaryEmail && (
                                    <ProfileEmailDisplay
                                        isDesktop={true}
                                        {...primaryEmail}
                                        {...profile}
                                        isPrimary={true}
                                        isChecked={
                                            primaryEmail.monthlyScanSubscription || false
                                        }
                                        onToggleMonthlyReportSuccess={
                                            onToggleMonthlyReportSuccess
                                        }
                                        onToggleConfirmEmailDelete={
                                            onToggleConfirmEmailDelete
                                        }
                                        isDeletingSecondaryEmail={
                                            isDeletingSecondaryEmail
                                        }
                                    />
                                )}
                                {secondaryEmails?.map((secondary, i) => {
                                    return (
                                        <ProfileEmailDisplay
                                            isDesktop={true}
                                            {...secondary}
                                            {...profile}
                                            isPrimary={false}
                                            key={`email-row-${i}`}
                                            isChecked={
                                                secondary.monthlyScanSubscription || false
                                            }
                                            onToggleMonthlyReportSuccess={
                                                onToggleMonthlyReportSuccess
                                            }
                                            onToggleConfirmEmailDelete={
                                                onToggleConfirmEmailDelete
                                            }
                                            isDeletingSecondaryEmail={
                                                isDeletingSecondaryEmail
                                            }
                                        />
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            </OverviewCard>
        </Box>
    );
};

export default ProfileEmailsListDesktop;
