import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    PinInput,
    PinInputField,
    Stack,
    Text,
} from '@chakra-ui/react';
import React from 'react';

const OtpForm = ({
    rootProps,
    label,
    disclaimer,
    otpCount,
    buttonDisabled,
    buttonLabel,
    isInvalid = false,
    isLoading = false,
    onChange = () => {},
    onComplete = () => {},
    onFormSubmit = () => {},
    errorMessage,
}: {
    rootProps: any;
    label: string;
    disclaimer: any;
    otpCount: number;
    buttonDisabled: boolean;
    buttonLabel: string;
    isInvalid?: boolean;
    isLoading?: boolean;
    onChange?: any;
    onComplete?: any;
    onFormSubmit?: any;
    errorMessage?: string;
}) => {
    const PinInputComponent: any = [];

    for (let x = 0; x < otpCount; x++) {
        PinInputComponent.push(<PinInputField flexGrow={1} key={`otp-input-${x}`} />);
    }
    return (
        <form onSubmit={onFormSubmit}>
            <Stack {...rootProps}>
                <FormControl>
                    <FormLabel>{label}</FormLabel>
                    <HStack w="full">
                        <PinInput
                            otp
                            size="lg"
                            placeholder=""
                            autoFocus
                            onChange={onChange}
                            isInvalid={isInvalid}
                            onComplete={onComplete}
                        >
                            {PinInputComponent}
                        </PinInput>
                    </HStack>
                    {isInvalid && errorMessage && (
                        <Text m={'10px 0'} color={'red'} fontSize={'16px'}>
                            {errorMessage}
                        </Text>
                    )}
                    <FormHelperText w="full">{disclaimer}</FormHelperText>
                </FormControl>
                <Button disabled={buttonDisabled} type="submit" isLoading={isLoading}>
                    {buttonLabel}
                </Button>
            </Stack>
        </form>
    );
};

export default OtpForm;
