import * as React from 'react';

const AssetPasswordsSvg = props => (
    <svg width={49} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.279 17.474v-.903A8.571 8.571 0 0 1 23.85 8v0a8.571 8.571 0 0 1 8.572 8.571v6.587"
            stroke="#111"
            strokeLinecap="round"
        />
        <rect
            x={11.85}
            y={23.158}
            width={24}
            height={16.842}
            rx={1}
            stroke="#111"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default AssetPasswordsSvg;
