import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { getPageStyles } from '../../config/theme';

const ArticleTopicFilter = ({ topics, selectedTopic, onSelectTopic }) => {
    const { filterTag } = getPageStyles('DiscoverSecurityPage');

    return (
        <HStack id={`topics-filter`} w={{ base: '100vw', md: 'auto' }}>
            {topics &&
                topics?.map((topic, i) => {
                    const selected = selectedTopic === topic ? true : false;
                    return (
                        <Box key={`topic-filter-${i}`}>
                            {selected ? (
                                <Box
                                    {...filterTag}
                                    backgroundColor={'brand.primary.500'}
                                    color={'white'}
                                    onClick={() => {
                                        onSelectTopic('all');
                                    }}
                                >
                                    {topic}
                                </Box>
                            ) : (
                                <Box
                                    {...filterTag}
                                    onClick={() => {
                                        onSelectTopic(topic);
                                    }}
                                >
                                    {topic}
                                </Box>
                            )}
                        </Box>
                    );
                })}
        </HStack>
    );
};

export default ArticleTopicFilter;
