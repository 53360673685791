import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

const MinimizeIconSvg: React.FC<IconProps> = ({ w, h, ...restProps }) => (
    <Icon
        width={w}
        height={w}
        fill="none"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
    >
        <circle cx={24} cy={24} r={23} fill="#fff" stroke="#333F48" strokeWidth={2} />
        <path fill="#333F48" d="M17.333 33.334h13.333v2.667H17.333z" />
    </Icon>
);

export default MinimizeIconSvg;
