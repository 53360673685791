import { IconProps, Icon } from '@chakra-ui/react';
import * as React from 'react';

const HeaderDesktopLogoIcon: React.FC<IconProps> = props => (
    <Icon
        width={'68px'}
        height={'17px'}
        viewBox={'0 0 68 17'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.115 12.64c0 .93.75 1.487 1.502 1.487.845 0 1.455-.511 1.455-1.395 0-1.256-1.127-1.349-2.206-1.674-1.127-.326-2.347-1.21-2.347-3.023 0-1.814 1.408-3.117 3.145-3.117 1.173 0 2.065.512 2.675 1.442L17.79 7.85c-.187-.465-.563-.745-.985-.745-.61 0-.892.466-.892.884 0 .977 1.267.977 2.488 1.395 1.173.42 2.206 1.303 2.206 3.21 0 2.232-1.643 3.767-3.943 3.767-2.347 0-3.85-1.72-3.85-3.814h2.3v.093ZM31.59 5.104h-2.487v6.185c0 1.675-.845 2.838-2.488 2.838s-2.488-.838-2.488-2.838V5.105H21.64v6.743c0 2.977 1.55 4.605 4.366 4.605 1.549 0 2.581-.744 3.098-1.395v1.256h2.487V5.104Zm1.972 11.255h2.488v-6.232c0-1.675 1.126-2.791 2.675-2.791h.235V4.965c-1.596 0-2.394.605-2.91 1.302V5.105h-2.488V16.36Zm7.04 0h2.488V5.105h-2.487V16.36ZM41.87.5c-.939 0-1.69.744-1.69 1.674 0 .93.751 1.675 1.69 1.675s1.69-.744 1.69-1.675c0-.93-.751-1.674-1.69-1.674Zm8.543 13.581c-1.925 0-3.239-1.395-3.239-3.349 0-1.953 1.314-3.348 3.239-3.348 1.924 0 3.239 1.395 3.239 3.348.047 1.954-1.268 3.349-3.24 3.349Zm0-9.07c-3.333 0-5.774 2.466-5.774 5.721 0 3.256 2.441 5.721 5.774 5.721s5.773-2.465 5.773-5.72c.047-3.256-2.394-5.722-5.773-5.722Zm17.461 5.024c0-2.838-2.347-5.163-5.21-5.163s-5.21 2.325-5.21 5.163v6.279h2.44v-6.28a2.762 2.762 0 0 1 2.77-2.744c1.502 0 2.77 1.21 2.77 2.745v6.279h2.44v-6.28Zm-62.1 3.953c1.83 0 3.286-1.488 3.286-3.256 0-1.814-1.502-3.255-3.286-3.255-1.83 0-3.286 1.488-3.286 3.255-.047 1.814 1.455 3.256 3.286 3.256Zm0-8.976c3.192 0 5.774 2.558 5.774 5.72v5.628H9.06v-1.256C8.074 16.221 6.854 16.5 5.774 16.5 2.582 16.5.001 13.94.001 10.779-.046 7.569 2.582 5.01 5.774 5.01Z"
            fill="#fff"
        />
    </Icon>
);

export default HeaderDesktopLogoIcon;
