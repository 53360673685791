import * as React from 'react';

const AssetBankAccountsSvg = props => (
    <svg width={48} height={49} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23.8 8.6v4M23.8 36.6v4M30.8 16.6s-1-4-7-4-7 3.913-7 6c0 8.28 14 3.93 14 12 0 2.087-1 6-7 6s-7-4-7-4"
            stroke="#111"
            strokeLinecap="round"
        />
    </svg>
);

export default AssetBankAccountsSvg;
