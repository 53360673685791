import { entity, persistence } from 'simpler-state';
import { Nullable } from '../../utils/types';
import { ChatManager } from '../ChatManager';
import { v4 as uuidv4 } from 'uuid';

export const chatRequestIdEntity = entity<Awaited<Nullable<string>>>(null, [
    persistence('chatRequestId', { storage: 'session' }),
]);

export const chatConversationIdEntity = entity<Awaited<Nullable<string>>>(null, [
    persistence('conversationId', { storage: 'session' }),
]);

export const chatVisitorIdEntity = entity<Awaited<Nullable<string>>>(null, [
    persistence('visitorId', { storage: 'session' }),
]);

export const hasActiveChatEntity = entity<Awaited<Nullable<boolean>>>(null, [
    persistence('hasActiveChat', { storage: 'session' }),
]);

export const endChatActivity = () => {
    chatRequestIdEntity.set(null);
    chatConversationIdEntity.set(null);
    chatVisitorIdEntity.set(null);
    hasActiveChatEntity.set(null);
};

export const setChatRequestIdEntity = (requestId: string) => {
    chatRequestIdEntity.set(requestId);
};

export const setChatVisitorIdEntity = (visitorId: string) => {
    chatVisitorIdEntity.set(visitorId);
};

export const setHasActiveChatEntity = (hasActiveChat: boolean) => {
    hasActiveChatEntity.set(hasActiveChat);
};

export const getChatSession = (chatRequestId: Nullable<string>) => {
    let tempChatRequestId = uuidv4().toUpperCase();
    let tempChatVisitorId = uuidv4().toUpperCase();
    let conversationId: Nullable<string> = null;
    let hasActiveChat: any = null;
    let setNewIds = () => {
        hasActiveChatEntity.set(false);
        chatRequestIdEntity.set(tempChatRequestId);
        chatConversationIdEntity.set(tempChatVisitorId);
    };

    if (!!chatRequestId) {
        //check if request exists and is accepted
        ChatManager.getChatRequest(chatRequestId).then((data: any) => {
            //check if conversation exists
            //set all data
            if (
                data?.data?.getEncryptedChatRequest?.requestId &&
                data?.data?.getEncryptedChatRequest &&
                (data.data.getEncryptedChatRequest.requestStatus === 'Interacting' ||
                    data.data.getEncryptedChatRequest.requestStatus === 'Accepted')
            ) {
                ChatManager.getConversationByRequestId(
                    data.data.getEncryptedChatRequest.requestId
                ).then((result: any) => {
                    if (result?.data?.getConversation?.conversationId) {
                        hasActiveChatEntity.set(true);
                        chatRequestIdEntity.set(chatRequestId);
                        chatConversationIdEntity.set(
                            result.data.getConversation.conversationId
                        );
                    } else {
                        setNewIds();
                    }
                });
            } else {
                setNewIds();
            }
        });
    } else {
        setNewIds();
    }
    return { hasActiveChat, chatRequestId, conversationId };
};
