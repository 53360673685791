import { Box } from '@chakra-ui/react';
import React from 'react';

const ArticleImagePlaceholder = ({
    width = { base: '164px', md: '300px' },
    height = { base: '110px', md: '200px' },
    style,
}: {
    width: any;
    height: any;
    style: any;
}) => {
    return <Box w={width} h={height} {...style} border={`1px solid #eee`}></Box>;
};

export default ArticleImagePlaceholder;
