import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    FormLabel,
    HStack,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartScanLogo from '../assets/logos/SmartScanLogo';
import Header from '../components/header/Header';
import FullWidth from '../components/layouts/FullWidth';
import { AppRoutes } from '../config/config';
import useContent from '../hooks/useContent';
import useErrorToast from '../hooks/useErrorToast';
import { registerPrimaryUser } from '../services/apiService';

const SimpleRegistrationPage = () => {
    const content = useContent();
    const navigate = useNavigate();
    const errToast = useErrorToast();
    const [mdn, setMdn] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');

    const { mutate: register, isLoading: isRegistering } = useMutation({
        mutationFn: registerPrimaryUser,
        onSuccess: () => {
            navigate(AppRoutes.LOGIN);
        },
        onError: (err: AxiosError<Error>) => {
            errToast(err);
        },
    });

    const submit = useCallback(
        e => {
            e.preventDefault();
            register({ email, mdn, firstName, lastName });
        },
        [email, mdn, firstName, lastName, register]
    );

    return (
        <FullWidth id={'simple-registration-page'}>
            <Header authorized={false}></Header>
            <Box marginTop={{ base: '84px', md: '112px' }}>
                <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
                    <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                        <HStack alignItems={'center'}>
                            <Text fontSize={24} fontWeight={700} marginRight={'7px'}>
                                {content('forms.login.header.welcome')}
                            </Text>
                            <SmartScanLogo props={{ style: { margin: 0 } }} />
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={submit}>
                            <VStack spacing={3} alignItems={'baseline'}>
                                <FormLabel>MDN</FormLabel>
                                <Input
                                    type={'text'}
                                    value={mdn}
                                    onChange={e => {
                                        setMdn(e.target.value || '');
                                    }}
                                />
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type={'email'}
                                    value={email}
                                    onChange={e => {
                                        setEmail(e.target.value || '');
                                    }}
                                />
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    type={'text'}
                                    value={firstName}
                                    onChange={e => {
                                        setFirstName(e.target.value || '');
                                    }}
                                />
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    type={'text'}
                                    value={lastName}
                                    onChange={e => {
                                        setLastName(e.target.value || '');
                                    }}
                                />
                                <Divider />
                                <Button
                                    type={'submit'}
                                    isLoading={isRegistering}
                                    disabled={isRegistering}
                                    variant={'solid'}
                                >
                                    Sign Up
                                </Button>
                            </VStack>
                        </form>
                    </CardBody>
                </Card>
            </Box>
        </FullWidth>
    );
};

export default SimpleRegistrationPage;
